import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { message, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadFile } from 'antd/lib/upload/interface';

const Avatar = (props: any) => {
    const [fileList, setFileList] = useState<UploadFile[]>();

    const onChange = ({ fileList: newFileList }: any) => {
        setFileList(newFileList);
    };

    useEffect(() => {
        props.returnFile(fileList);
    }, [fileList])

    const onPreview = async (file: any) => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    return (
        <ImgCrop minZoom={0} aspect={props.aspect}>
            <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
                customRequest={({ file, onSuccess, onError }: any) => {
                    return onSuccess(props.getImg(file));
                }}
            // beforeUpload={(file) => {
            //     const isJPG = file.type === 'image/jpeg';
            //     if (!isJPG) {
            //         message.error(`${file.name} is not a jpg file`);
            //     }
            //     return isJPG;
            // }}
            >
                {fileList === undefined ? '+ Upload' : fileList.length < 1 && '+ Upload'}
            </Upload>
        </ImgCrop>
    );
};


export default Avatar;