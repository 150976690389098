import { Button, Card, Layout, message, Modal, PageHeader, Row, Select, Table, Tabs } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import React, { CSSProperties, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getColumnSearchProps } from "../../helpers/getColumnSearchProps";
import { http } from "../../helpers/http";
import { AxiosResponse } from "axios";
import moment from "moment";

const tabsTranslation = {
    "paid_without_med": "Apmaksāta, nav iegūta",
    "attached_to_group_unpaid": "Nav apmaksāta, nav iegūta",
    "two_month_notice": "Beigsies tuvāko 2 mēn. laikā"
} as { [key: string]: string };

const statusBlockStyle = {
    width: 16,
    height: 16,
    borderRadius: 4,
    display: "inline-block"
} as CSSProperties;

export const MedicalOverview = () => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(undefined as string | undefined);
    const [tabs, setTabs] = useState([] as string[]);
    const [data, setData] = useState([] as any);
    const [tableState, setTableState] = useState({ pagination: { current: 1 }, filters: {}, sorter: {} } as any);
    const [loading, setLoading] = useState(false);
    const [cities, setCities] = useState([] as any);

    const getCities = () => {
        http.get("rest/cities").then((res: AxiosResponse) => {
            setCities(res.data.data);
        }).catch(() => {
            setCities([]);
        })
    }

    const getPossibleTabs = () => {
        http.get('rest/medical-paid-finder-tabs').then((res: AxiosResponse) => {
            setTabs(res.data);
        }).catch(() => {
            message.error("Neizdevās iegūt datus");
        });
    }

    const getData = async (params: any = { pagination: { current: 1 }, tab: activeTab }) => {
        setLoading(true);
        setData([]);
        http.get(`rest/medical-paid-finder`, {
            params: {
                tab: params.tab,
                page: params.pagination.current,
                filters: params.filters,
                sortField: params.sortField,
                sortOrder: params.sortOrder,
            }
        }).then((res: AxiosResponse) => {
            setLoading(false);
            setData(res.data.data);
            // setData([
            //     {
            //         "student": "Jana Līkuma",
            //         "student_id": 56562,
            //         "email": "j.likuma@hotmail.com",
            //         "phone_number": "20222326",
            //         "personal_identity_number": "300696-12468",
            //         "city_id": 1,
            //         "city": "Rīga",
            //         "study_group_id": 4052,
            //         "created_at": "2024-12-12 08:53:34",
            //         "updated_at": "2024-12-12 08:53:42",
            //         "csdd_nr": "4654-472",
            //         "start_date": "2024-12-10",
            //         "category_id": 1,
            //         "category": "B",
            //         "language": "Latviešu",
            //         "passed_modules": 0,
            //         "passed_lessons": 0,
            //         "medical_summary_state": null,
            //         "medical_summary_date": null,
            //         "expire_date": null
            //     }
            // ])
            setTableState({
                pagination: {
                    current: res.data.page,
                    total: res.data.total,
                },
                filters: params.filters,
                tab: params.tab
            })
        }).catch(() => {
            setLoading(false);
            setData([]);
        })
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setTableState({ pagination, filters, sorter });
        getData({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            filters,
            tab: activeTab
        });
    };

    const setMedicalSummaryState = (student: number, category: number, state: number) => {
        http.get(`/rest/set-medical-summary/${student}/${category}${state === -1 ? "" : `/${state}`}`).then((res: AxiosResponse) => {
            message.success("Statuss nomainīts");
            // need to fill with res data
            setData(data.map((record: any) => {
                if (record.student_id === student) {
                    record.medical_summary_state = state === -1 ? null : state;
                    record.medical_summary_date = moment().format("YYYY-MM-DD HH:mm:ss");
                }
                return record;
            }));
        }).catch(() => {
            message.error("Neizdevās nomainīt statusu");
        });
    }

    const columns = [
        {
            title: "Status",
            dataIndex: "medical_summary_state",
            key: "medical_summary_state",
            filters: [
                { text: "Jauns", value: "null" },
                { text: <div style={{ ...statusBlockStyle, backgroundColor: "green" }}></div>, value: "1" },
                { text: <div style={{ ...statusBlockStyle, backgroundColor: "red" }}></div>, value: "0" },
            ],
            render: (state: any, record: any) => {
                return <Select
                    className="strange-color-status-select"
                    style={{ width: 100, background: state === null ? "white" : state === 1 ? "green" : "red" }}
                    value={state === null ? -1 : state}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onChange={(value) => {
                        setMedicalSummaryState(record.student_id, record.category_id, value);
                    }}
                >
                    <Select.Option value={-1}>Jauns</Select.Option>
                    <Select.Option value={1}><div style={{ ...statusBlockStyle, backgroundColor: "green" }}></div></Select.Option>
                    <Select.Option value={0}><div style={{ ...statusBlockStyle, backgroundColor: "red" }}></div></Select.Option>
                </Select>
            }
        },
        {
            title: "Students",
            dataIndex: "student",
            key: "student",
            ...getColumnSearchProps(),
            render: (student: any, record: any) => {
                return record.medical_group_id && activeTab === "attached_to_group_unpaid"
                    ? <Row style={{ flexWrap: "nowrap" }} align="middle">
                        <Button
                            type="text"
                            danger
                            style={{ fontSize: 16 }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            icon={<DeleteOutlined />}
                        ></Button>
                        <p style={{ marginBottom: 0 }}>{student}</p>
                    </Row>
                    : student
            }
        },
        {
            title: "E-pasts",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps(),
        },
        {
            title: "Tālruņa nr.",
            dataIndex: "phone_number",
            key: "phone_number",
            ...getColumnSearchProps(),
        },
        {
            title: "Pilsēta",
            dataIndex: "city",
            key: "city",
            filters: cities?.map((city: any) => ({ text: city.value, value: city.value })),
        },
        {
            title: "Kategorija",
            dataIndex: "category",
            key: "category",
        },
        {
            title: "Apstrādāts",
            dataIndex: "medical_summary_date",
            ...getColumnSearchProps("calendar"),
            render: (summaryDate: any) => {
                return summaryDate ? moment(summaryDate, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm:ss") : ""
            }
        }
    ] as any;

    const groupColumn = [
        {
            title: "Grupas sākuma datums",
            dataIndex: "start_date",
            key: "start_date",
            ...getColumnSearchProps("calendar"),
            sorter: true,
            render: (startDate: any) => {
                return startDate ? moment(startDate, "YYYY-MM-DD").format("DD.MM.YYYY") : "Nav grupas"
            }
        },
        {
            title: "Apmeklētas nodarbības",
            render: (_: any, record: any) => {
                return record?.passed_lessons + record?.passed_modules
            }
        },
        // {
        //     title: "Online",
        //     dataIndex: "online_date",
        //     key: "online_date",
        //     ...getColumnSearchProps("calendar"),
        //     render: (group_date: any, record: any) => {
        //         return group_date
        //             ? <div>
        //                 <p style={{ marginBottom: 2 }}>{group_date}</p>
        //                 <p style={{ fontSize: 10 }}>{record.online_from} - {record.online_to}</p>
        //             </div>
        //             : ""
        //     }
        // },
        // {
        //     title: "Klātienē",
        //     dataIndex: "medical_date",
        //     key: "medical_date",
        //     ...getColumnSearchProps("calendar"),
        //     render: (group_date: any, record: any) => {
        //         return group_date
        //             ? <div>
        //                 <p style={{ marginBottom: 2 }}>{group_date}</p>
        //                 <p style={{ fontSize: 10 }}>{record.medical_from} - {record.medical_to}</p>
        //             </div>
        //             : ""
        //     }
        // }
    ]

    useEffect(() => {
        if (activeTab === undefined) return;
        getData();
    }, [activeTab])

    useEffect(() => {
        if (tabs.length === 0) return;
        setActiveTab(tabs[0]);
    }, [tabs])

    useEffect(() => {
        getPossibleTabs();
        getCities();
    }, [])

    return <Layout>
        <PageHeader
            title="Medicīnisko izziņu pārskats"
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF", width: "100%" }}
        />
        <Card style={{ width: "100%", margin: 20 }}>
            <Tabs
                defaultActiveKey={activeTab}
                onChange={(key) => {
                    if (loading) return;
                    setActiveTab(key)
                }}
            >
                {
                    tabs?.map((tab: string) => {
                        return <Tabs.TabPane tab={tabsTranslation[tab]} key={tab} disabled={loading}>
                            <Table
                                rowKey={(record: any) => record.student_id}
                                dataSource={data}
                                columns={[...columns, ...groupColumn]}
                                onChange={handleTableChange}
                                loading={loading}
                                pagination={{
                                    ...tableState.pagination,
                                    pageSize: 15,
                                    showSizeChanger: false,
                                    position: ['bottomCenter'],
                                }}
                                onRow={(record) => {
                                    return {
                                        onClick: () => {
                                            window.open(`${process.env.REACT_APP_ADMIN_URL ?? "http://localhost:3000/"}groups/student-opening/${record.student_id}`, "_blank");
                                        }
                                    }
                                }}
                            />
                        </Tabs.TabPane>
                    })
                }
                {/* <Tabs.TabPane tab="Ir samaksāts, nav reģistrēts CSDD" key="paid_not_in_csdd" disabled={loading}>
                    <Table
                        rowKey={(record: any) => record.student_id}
                        dataSource={data}
                        columns={[...columns, ...groupColumn]}
                        onChange={handleTableChange}
                        loading={loading}
                        pagination={{
                            ...tableState.pagination,
                            pageSize: 15,
                            showSizeChanger: false,
                            position: ['bottomCenter'],
                        }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    window.open(`${process.env.REACT_APP_ADMIN_URL ?? "http://localhost:3000/"}groups/student-opening/${record.student_id}`, "_blank");
                                }
                            }
                        }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Ir grupā, nav samaksājuši" key="attached_to_group_unpaid" disabled={loading} >
                    <Table
                        rowKey={(record: any) => record.student_id}
                        dataSource={data}
                        columns={[...columns, ...groupColumn]}
                        onChange={handleTableChange}
                        loading={loading}
                        pagination={{
                            ...tableState.pagination,
                            pageSize: 15,
                            showSizeChanger: false,
                            position: ['bottomCenter'],
                        }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    window.open(`${process.env.REACT_APP_ADMIN_URL ?? "http://localhost:3000/"}groups/student-opening/${record.student_id}`, "_blank");
                                }
                            }
                        }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Nav grupā, ir samaksājuši" key="paid_without_group" disabled={loading} >
                    <Table
                        rowKey={(record: any) => record.student_id}
                        dataSource={data}
                        columns={[...columns]}
                        onChange={handleTableChange}
                        loading={loading}
                        pagination={{
                            ...tableState.pagination,
                            pageSize: 15,
                            showSizeChanger: false,
                            position: ['bottomCenter'],
                        }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    window.open(`${process.env.REACT_APP_ADMIN_URL ?? "http://localhost:3000/"}groups/student-opening/${record.student_id}`, "_blank");
                                }
                            }
                        }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Nav PMP, nav pieteikušies" key="pmpless" disabled={loading}>
                    <Table
                        rowKey={(record: any) => record.student_id}
                        dataSource={data}
                        columns={[...columns]}
                        onChange={handleTableChange}
                        loading={loading}
                        pagination={{
                            ...tableState.pagination,
                            pageSize: 15,
                            showSizeChanger: false,
                            position: ['bottomCenter'],
                        }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    window.open(`${process.env.REACT_APP_ADMIN_URL ?? "http://localhost:3000/"}groups/student-opening/${record.student_id}`, "_blank");
                                }
                            }
                        }}
                    />
                </Tabs.TabPane> */}
            </Tabs>
        </Card>
    </Layout>
};