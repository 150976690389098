import { Layout, Row, PageHeader, Col, DatePicker, Button, Card, Select, Table, Input, message, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosResponse } from "axios";
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';

type SchoolInvoicesPageProps = {
    school: any;
    setScreen: (screen: "overview" | "balance" | "invoices") => void;

};

export const SchoolInvoicesPage = ({ ...props }: SchoolInvoicesPageProps) => {
    const [period, setPeriod] = useState([
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month')
    ] as any);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState<any[]>([]);

    const deleteHandler = (invoice: number) => {
        Modal.confirm({
            title: "Vai tiešām vēlaties dzēst šo rēķinu?",
            onOk: () => {
                http.post(`edrive-invoices/delete-invoice/${invoice}`).then(() => {
                    message.success("Rēķins dzēsts!");
                    getSchoolInvoices();
                }).catch(() => {
                    message.error("Kļūda dzēšanas laikā!");
                });
            }
        });

    };

    const getSchoolInvoices = (periodSetting: any = period) => {
        setLoading(true);
        http.get(`edrive-invoices/get-school-invoice-data/${props.school.id}`, {
            params: {
                existing_invoices: 1,
                from: moment(periodSetting[0], "DD.MM.YYYY").format("YYYY-MM-DD"),
                to: moment(periodSetting[1], "DD.MM.YYYY").format("YYYY-MM-DD")
            }
        }).then((res: AxiosResponse) => {
            console.log(res);
            setInvoices(res.data);
            setLoading(false);
        }).catch(() => {
            message.error("Kļūda!");
            setLoading(false);
        });
    };

    useEffect(() => {
        getSchoolInvoices();
    }, [props.school]);

    const columns = [
        {
            title: 'Numurs',
            dataIndex: 'invoice_number'
        },
        {
            title: 'Summa',
            dataIndex: 'student_rels',
            render: (studentRels: any, record: any) => {
                return studentRels.reduce((acc: any, studentRel: any) => {
                    return acc + Number(studentRel.price);
                }, 0) + " €";
            }
        },
        {
            title: 'Rēķina datums',
            dataIndex: 'date',
            render: (date: string) => {
                return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
            }
        },
        {
            title: 'Par periodu',
            dataIndex: 'period',

        },
        {
            title: 'Darbības',
            dataIndex: 'pdf',
            render: (pdf: any, record: any) => {
                return pdf?.web_location
                    ? <Row>
                        <Button
                            onClick={() => window.open(pdf.web_location, "_blank")}
                        >
                            <SaveOutlined />
                        </Button>
                        <Button
                            danger
                            style={{ marginLeft: 10 }}
                            onClick={() => deleteHandler(record.id)}
                        >
                            <DeleteOutlined />
                        </Button>
                    </Row>
                    : <></>
            }
        }
    ] as any;

    return <Layout>
        <Row>
            <PageHeader
                style={{ backgroundColor: "#FFFFFF", width: "100%" }}
                title={`Skola: ${props.school?.name || ""} Rēķini`}
                onBack={() => props.setScreen("overview")}
            />
            <Col span={24} style={{ backgroundColor: "#FFFFFF" }}>
                <Row justify="center" align="middle">
                    <b>Periods: </b>
                    <DatePicker.RangePicker
                        style={{ margin: 20 }}
                        defaultValue={period}
                        format="DD.MM.YYYY"
                        onChange={(e: any) => setPeriod(e)}
                    />
                    <Button
                        style={{ marginLeft: 8 }}
                        onClick={() => {
                            if (period === null) return;
                            getSchoolInvoices(period);
                        }}
                    >
                        Atlasīt
                    </Button>
                </Row>
            </Col>
            <Col span={24}>
                <Card>
                    <Row justify="end">
                        <Select
                            value={pageSize}
                            style={{ width: 150, marginBottom: 10 }}
                            onChange={(e: any) => setPageSize(e)}
                            disabled={loading}
                        >
                            <Select.Option value={10}>10 / lappuse</Select.Option>
                            <Select.Option value={20}>20 / lappuse</Select.Option>
                            <Select.Option value={50}>50 / lappuse</Select.Option>
                            <Select.Option value={100}>100 / lappuse</Select.Option>
                            <Select.Option value={-1}>Visi ieraksti</Select.Option>
                        </Select>
                    </Row>
                    <Table
                        rowKey={record => record.id}
                        columns={columns}
                        dataSource={invoices}
                        loading={loading}
                        pagination={
                            pageSize === -1
                                ? false
                                : {
                                    pageSize
                                }}
                        style={{ marginBottom: 20 }}
                    />
                    {
                        pageSize !== -1
                            ? <></>
                            : <Row justify="end">
                                <Select
                                    value={pageSize}
                                    style={{ width: 150 }}
                                    onChange={(e: any) => setPageSize(e)}
                                    disabled={loading}
                                >
                                    <Select.Option value={10}>10 / lappuse</Select.Option>
                                    <Select.Option value={20}>20 / lappuse</Select.Option>
                                    <Select.Option value={50}>50 / lappuse</Select.Option>
                                    <Select.Option value={100}>100 / lappuse</Select.Option>
                                    <Select.Option value={-1}>Visi ieraksti</Select.Option>
                                </Select>
                            </Row>
                    }
                </Card>
            </Col>
        </Row>
    </Layout >
}