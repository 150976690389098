import { Card, Row, Col, Form, Select, Button, message } from "antd"
import { AxiosError, AxiosResponse } from "axios";
import React, { useState } from "react"
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";

type CategoriesInformationProps = {
    student: any;
    categories: any;
    cities: any;
    languages: any;
    refresh: () => void;
}

export const CategoriesInformation = ({ student, categories, cities, refresh }: CategoriesInformationProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState(null as number | null);
    const [csddProhibited, setCsddProhibited] = useState({} as any);

    const onFinish = (values: any) => {

        console.log(values)
        processData(values)
    }

    const processData = (data: any) => {

        let objectToSend = {} as any;
        Object.keys(data).forEach((key: any) => {
            objectToSend = {
                ...objectToSend,
                [key.split("-")[1]]: {
                    ...objectToSend[key.split("-")[1]],
                    [key.split("-")[0]]: data[key]
                }
            }
            // if (objectToSend.hasOwnProperty([key.split("-")[1]])) {
            //     objectToSend = {
            //         ...objectToSend,
            //         [key.split("-")[1]]: {
            //             ...objectToSend[key.split("-")[1]],
            //             [key.split("-")[0]]: data[key]
            //         }
            //     }
            // } else {
            //     objectToSend = {
            //         ...objectToSend,
            //         [key.split("-")[1]]: {
            //             [key.split("-")[0]]: data[key]
            //         }
            //     }
            // }

        })
        setLoading(true)
        http.post(`/rest/change-multi-cat/${student.id}`, {
            multidata: objectToSend
        }).then((res: AxiosResponse) => {
            setLoading(false)
            message.success("Kategoriju informācija ir atjaunota")
        }).catch(() => {
            setLoading(false)
            message.error("Kļūda!")
        })
    }

    const addCategory = () => {
        setLoading(true);
        if (category) {
            http.get(`/rest/student-add-category/${student.id}/${category}`).then((res: AxiosResponse) => {
                setLoading(false);
                setCategory(null);
                refresh();
            }).catch(() => {
                message.error("Kļūda!");
                setLoading(false);
            })
        }
    }

    const deleteCategory = (categoryID: number) => {
        setLoading(true);
        http.get(`/rest/student-remove-category/${student.id}/${categoryID}`).then((res: AxiosResponse) => {
            refresh();
            setLoading(false);
        }).catch(() => {
            message.error("Kļūda!");
            setLoading(false);
        })
    }

    const checkCatWithCsdd = async (
        category: number,
    ) => {
        //S-00003. Sakarā ar liegumu, personai nevar veikt uzdoto darbību!
        //G-01004. Uzdoto darbību nevar veikt, jo personai nav atbilstošs vecums.
        //U-01143. Uzdoto personu nevar pieteikt uz šādu apmācību, jo persona iepriekš nav ieguvusi ne atbilstošu kategoriju, ne arī nokārtojusi atbilstošās kategorijas braukšanas eksāmenu.

        const errors = {
            'G-01006': 'wrong_personal_code',
            'G-01027': 'wrong_document_number',
        };

        const fatalErrors = {
            'S-00003': 'csdd_prohibition',
            'G-01004': 'csdd_age_restricted',
            'U-01143': 'csdd_pre_cat_restricted',
        };

        const result = await http
            .post('rest/check-allowed-cat', { code: student?.personal_identity_number, document: student?.passport_or_id_number, category })
            .then((res: AxiosResponse) => {
                console.log('CSDD cat check', res.data);
                if (!!res.data?.allowed) {
                    return {
                        status: true,
                        msg: null,
                        prohibited: false,
                    };
                }
                return {
                    status: false,
                    msg: null,
                    prohibited: false,
                };
            })
            .catch((err: AxiosError) => {
                if (err.response?.status === 400) {
                    const originalErrorObject = err.response?.data as {
                        allowed: { errors: { msg: string }[] };
                    };
                    const originalErrorMsg = originalErrorObject.allowed?.errors[0]?.msg;
                    const translatedErrorKey = Object.keys(errors).find((key: string) => {
                        return originalErrorMsg?.includes(key);
                    });
                    const translatedFatalErrorKey = Object.keys(fatalErrors).find(
                        (key: string) => {
                            return originalErrorMsg?.includes(key);
                        },
                    );
                    const translatedFatalError =
                        fatalErrors[translatedFatalErrorKey as keyof typeof fatalErrors];
                    const translatedError =
                        errors[translatedErrorKey as keyof typeof errors];
                    const isFatalError = !!translatedFatalError;
                    return isFatalError
                        ? {
                            status: false,
                            msg: translatedFatalError,
                            prohibited: true,
                        }
                        : {
                            status: false,
                            msg: translatedError ?? 'please_check_credentials',
                            prohibited: false,
                        };
                }
                return {
                    status: false,
                    msg: null,
                    prohibited: false,
                };
            });
        setCsddProhibited((prev: any) => {
            return {
                ...prev,
                [category]: result,
            }
        })
        return result;
    };

    // const processCsddProhibition = (allowed: { status: boolean; msg: string | null; prohibited: boolean }) => {
    //     if (allowed.status) {
    //         setLoading(false);
    //         return <div>
    //             <b style={{ color: "#07ba2e" }}>Studentam nav CSDD liegumu šajā kategorijā</b>
    //         </div>
    //     } else {
    //         setLoading(false);
    //         return <div>
    //             <b style={{ color: "#d60000" }}>{allowed.msg}</b>
    //         </div>
    //     }
    // }

    return <Card
        style={{ marginBottom: 20 }}
        title={
            <>
                <strong>Mācību informācija</strong>

            </>}
        loading={Object.keys(student).length === 0 || categories.length === 0 || cities.length === 0}
    >
        <Form
            onFinish={onFinish}
        >
            {
                student.categories?.map((category: any) => {
                    return <Row gutter={20} key={category.id} style={{ border: "1px solid #f0f0f0", paddingTop: 16, paddingBottom: 16, borderRadius: 8 }}>
                        {
                            csddProhibited?.[category.category_id]?.status !== undefined
                                ? <Col span={24}>{
                                    csddProhibited?.[category.category_id]?.status
                                        ? <div style={{ marginBottom: 6 }}>
                                            <b style={{ color: "#07ba2e" }}>Studentam nav CSDD liegumu šajā kategorijā</b>
                                        </div>
                                        : <div style={{ marginBottom: 6 }}>
                                            <b style={{ color: "#d60000" }}>
                                                {
                                                    csddProhibited?.[category.category_id]?.msg
                                                        ? t(`csdd_errors_translated.${csddProhibited?.[category.category_id]?.msg}`)
                                                        : "Neatpazīta kļūda, pārbaudiet vai personas kods un dokumenta numurs ir pareizi"
                                                }
                                            </b>
                                        </div>
                                }
                                </Col>
                                : null
                        }
                        <Col flex={1}>
                            <Form.Item
                                name={`category_id-${category.id}`}
                                required
                                rules={[{ required: true, message: "Obligāts lauks" }]}
                                label="Kategorija"
                                initialValue={category.category_id}
                            >
                                <Select
                                    style={{ width: 150 }}
                                // onChange={(e: any) => this.studyGroupFilterHandler(e, "category")}
                                >
                                    {
                                        categories
                                            ? categories.filter((cat: any) => {
                                                return !cat.exam
                                            }).map((cat: any) => {
                                                return <Select.Option key={cat.id} value={cat.id}>{cat.value}</Select.Option>
                                            }) : null
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col flex={1} style={{ maxWidth: 200 }}>
                            <Button
                                style={{ marginBottom: 10 }}
                                onClick={() => {
                                    checkCatWithCsdd(category.category_id).then((res) => {
                                        console.log("csdd response", res)
                                    })
                                }}
                            >
                                Pārbaudīt CSDD liegumu
                            </Button>
                        </Col>
                        <Col flex={1}>
                            <Form.Item
                                name={`transmission-${category.id}`}
                                required
                                rules={[{ required: true, message: "Obligāts lauks" }]}
                                label="Pārnesumkārba"
                                initialValue={category.transmission}
                            >
                                <Select style={{ width: 150 }}>
                                    <Select.Option value="manual">Manuālā</Select.Option>
                                    <Select.Option value="auto">Automātiskā</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item
                                name={`city_id-${category.id}`}
                                required rules={[{ required: true, message: "Obligāts lauks" }]}
                                label="Pilsēta"
                                initialValue={category.city_id}
                            >
                                <Select
                                    style={{ width: 150 }}
                                // onChange={(e: any) => this.studyGroupFilterHandler(e, "cities")}
                                >
                                    {
                                        cities
                                            ? cities.map((city: any) => {
                                                return <Select.Option key={city.id} value={city.id}>{city.value}</Select.Option>
                                            }) : null
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        {
                            !category.only_driving && (category.study_group_id === null || Number(category.study_group_id) === Number(process.env.REACT_APP_NO_GROUP_ID))
                            && <Col>
                                <Button
                                    danger
                                    type="primary"
                                    onClick={() => deleteCategory(category.category_id)}
                                    loading={loading}
                                >
                                    Dzēst
                                </Button>

                            </Col>
                        }
                    </Row>
                })
            }
            <Row justify="end">
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                >
                    Saglabāt
                </Button>
            </Row>
        </Form>
        <h3><b>Pievienot papildus kategoriju</b></h3>
        <Select
            style={{ width: 150 }}
            value={category ?? undefined}
            onChange={(e: any) => {
                setCategory(e === undefined ? null : e)
            }}
        >
            {
                categories
                    ? categories.map((cat: any) => {
                        return <Select.Option key={cat.id} value={cat.id}>{cat.value}</Select.Option>
                    }) : null
            }
        </Select>
        <Button
            style={{ marginLeft: 10 }}
            onClick={addCategory}
        >
            Pievienot
        </Button>
    </Card >
}
