import { Button, Card, Checkbox, Col, DatePicker, Form, Input, message, Row, Select, Skeleton, TimePicker } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { http } from '../../helpers/http';
import { AgeCheck } from './AgeCheck';
import { AxiosResponse } from 'axios';
import { NotificationSettings } from './NotificationSettings';

type StudentGeneralInformationProps = {
    student: any;
    genders: any;
    categories: any;
    cities: any;
    languages: any;
    refreshStudent: () => void;
    permissions: any;
}

export const StudentGeneralInformation = ({ student, genders, refreshStudent, languages, permissions }: StudentGeneralInformationProps) => {
    const [form] = useForm();
    const [birthDate, setBirthDate] = useState(undefined as undefined | moment.Moment);
    const [haveOrphan, setHaveOrphan] = useState(false);
    const [loading, setLoading] = useState(false);

    const onFinish = (values: any) => {
        console.log(processData(values))
        setLoading(true)
        http.patch(`/rest/students/${student.id}`, processData(values)).then((res: AxiosResponse) => {
            message.success("Informācija saglabāta");
            setBirthDate(moment(res.data.data.birthdate, "YYYY-MM-DD"))
            refreshStudent();
            setLoading(false)
        }).catch((e: any) => {
            message.error("Neizdevās saglabāt");
            setLoading(false)
        });
    };

    const processData = (data: any) => {
        return {
            ...data,
            birthdate: moment(data.birthdate).format("YYYY-MM-DD"),
            crm_notifications_disabled: data.crm_notifications_disabled ? 1 : 0,
        }
    }

    useEffect(() => {
        if (student.birthdate) {
            const birthdate = moment(student.birthdate, "YYYY-MM-DD");
            setBirthDate(birthdate);
            form.setFieldsValue({ birthdate });
        }
        setHaveOrphan(student.have_orphan)
    }, [student])

    return <div>
        <Card style={{ marginBottom: 20 }} loading={Object.keys(student).length === 0 || languages.length === 0}>
            <Form
                form={form}
                initialValues={{
                    ...student,
                    birthdate: birthDate
                }}
                layout='vertical'
                onFinish={onFinish}
            >
                <Row gutter={20} align="bottom">
                    <Col span={6}>
                        <Form.Item name="first_name" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Vārds">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="last_name" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Uzvārds">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item name="gender" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Dzimums">
                            <Select>
                                {genders.map((item: any) => {
                                    return <Select.Option
                                        key={item}
                                        value={item}
                                    >
                                        {item === "male"
                                            ? "Vīrietis"
                                            : item === "female"
                                                ? "Sieviete"
                                                : item === "other"
                                                    ? "Cits"
                                                    : "Nav definēts"}
                                    </Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item name="phone_number" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Tālrunis">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20} align="bottom">
                    <Col span={6}>
                        <Form.Item name="email" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }, { type: "email", message: "Nav derīga e-pasta adrese" }]} label="E-pasta adrese">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            name="personal_identity_number"
                            required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]}
                            label={
                                <p style={{ margin: 0 }}>
                                    Personas kods&nbsp;
                                    <small>
                                        (Nepieciešams sinhronizācijai ar CSDD)
                                    </small>
                                </p>
                            }
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item name="passport_or_id_number" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Pases vai ID numurs">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Row gutter={10}>
                            <Col span={12}>
                                <Form.Item name="birthdate" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Dzimšanas datums">
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Col>
                                    <Form.Item valuePropName="checked" name="have_orphan" label="Aizgādnis">
                                        <Checkbox
                                            onChange={(e: any) => setHaveOrphan(e.target.checked)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col flex={1}>
                        <Form.Item name="declared_address" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Deklarētā adrese">
                            <Input />
                        </Form.Item>
                        {/* {
                            (permissions?.perms?.some((el: any) => el === "crm_notification_disabler") || permissions.ruler_of_universe)
                                ? <Form.Item valuePropName="checked" name="crm_notifications_disabled" label="Nesūtīt automātiskos paziņojumus par šo studentu">
                                    <Checkbox />
                                </Form.Item>
                                : <></>
                        } */}
                    </Col>
                    <Col flex={1}>
                        <Form.Item name="language_id" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Valoda">
                            <Select
                                style={{ width: 150 }}
                            // onChange={(e: any) => this.studyGroupFilterHandler(e, "languages")}
                            >
                                {
                                    languages
                                        ? languages.map((lang: any) => {
                                            return <Select.Option key={lang.id} value={lang.id}>{lang.value}</Select.Option>
                                        }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col flex={1}>
                        <Form.Item name="requisites" label="Rekvizīti (tikai juridiskai personai!)">
                            <Input.TextArea autoSize={{ maxRows: 4, minRows: 4 }} />
                        </Form.Item>
                        {/* <Form.Item
                        name="custom_driving_hours"
                        label={(
                            <div>
                                <p style={{ margin: 0 }}>Braukšanas limits dienā (akadēmiskās stundas)</p>
                                <small>Ja lauks ir tukšs, tad pēc noklusējuma ierobežojums ir {this.state.initialData?.category?.daily_driving_limit} akadēmiskās stundas</small>
                            </div>
                        )}
                    >
                        <Input
                            type="number"
                            style={{ width: 120 }}
                        />
                    </Form.Item> */}
                    </Col>
                </Row>
                {
                    !!haveOrphan
                    && <Row>
                        <Col span={24}>
                            <Card title="Aizgādnis">
                                <Row>
                                    <Col span={6}>
                                        <Form.Item name="orphan_name" label="Vārds">
                                            <Input style={{ width: 150 }} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item name="orphan_surname" label="Uzvārds">
                                            <Input style={{ width: 150 }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="orphan_code" label="Personas kods">
                                            <Input style={{ width: 150 }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="orphan_phone" label="Tālruņa nr.">
                                            <Input style={{ width: 150 }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                }
                <Row gutter={20} justify="end">
                    <Col>
                        <Button type="primary" loading={loading} htmlType="submit">Saglabāt</Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    </div>
}