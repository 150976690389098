import { Button, Col, Input, message, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { HowManyLessonsToOneContext } from "../../helpers/HowManyLessonsToOneContext";

export const EditLessonPrices = (props: any) => {
    const { howManyLessonsToOne } = useContext(HowManyLessonsToOneContext);
    const { lesson } = props;
    const howManyLessons = howManyLessonsToOne.some((number: Number) => number === lesson?.how_many_lessons)
        ? 1
        : lesson?.how_many_lessons
    const [prices, setPrices] = useState(lesson?.slot_prices);
    const [totals, setTotals] = useState(() => {
        const prices = lesson?.slot_prices
        const amount = Math.abs(Number(lesson?.amount) / howManyLessons)
        const afterChange = prices.reduce((prev: any, current: any) => {
            return prev + Number(current.rate) + (Number(current.rate_vat) / 100 * Number(current.rate))
        }, 0);
        const difference = amount - afterChange
        return {
            amount,
            beforeChange: afterChange,
            afterChange,
            difference
        }
    });

    const savePrices = async () => {
        await http.post(`/payments/update-student-payment/${lesson.payment_id}`, { prices })
            .then(() => {
                message.success("Maksājums saglabāts");
                props.updateBalance();
            })
    }

    const adjustPriceDiff = (position: any) => {
        setPrices((prev: any) => {
            const newPrices = JSON.parse(JSON.stringify(prev))?.map((price: any) => {
                if (price?.position !== position) return price;
                else {
                    const diff = totals.beforeChange - totals.afterChange
                    const vat = Number(price.rate_vat) / 100 + 1
                    price.rate = ((Number(price.rate) * vat) + diff) / vat
                    return price;
                }
            })
            const newFullPrice = newPrices.reduce((prev: any, current: any) => {
                const vat = Number(current.rate_vat) / 100 + 1
                return prev + (Number(current.rate) * vat)
            }, 0);
            const diff = totals.beforeChange - newFullPrice;
            newPrices.forEach((price: any) => {
                if (price.position === position) {
                    const vat = Number(price.rate_vat) / 100 + 1
                    const diffWithVat = diff / vat
                    price.rate = Number(price.rate) + diffWithVat
                } else return;
            });
            return newPrices;
        });
    }

    useEffect(() => {
        setPrices(lesson?.slot_prices);
        setTotals(() => {
            const prices = lesson?.slot_prices
            const amount = Math.abs(Number(lesson?.amount) / howManyLessons)
            const afterChange = prices.reduce((prev: any, current: any) => {
                return prev + Number(current.rate) + (Number(current.rate_vat) / 100 * Number(current.rate))
            }, 0);
            const difference = amount - afterChange
            return {
                amount,
                beforeChange: afterChange,
                afterChange,
                difference
            }
        })
        // console.log(lesson)
    }, [lesson]);

    useEffect(() => {
        setTotals((prev: any) => {
            return {
                ...prev,
                afterChange: prices.reduce((prev: any, current: any) => {
                    return prev + Number(current.rate) + (Number(current.rate_vat) / 100 * Number(current.rate))
                }, 0)
            }
        })
    }, [prices]);

    useEffect(() => {
        // console.log(totals, prices)
    }, [totals])

    return lesson !== undefined
        ? <Row>
            <Col>
                {
                    prices.map((price: any, index: any) => {
                        return <div style={{ margin: "5px 20px", height: 32 }} key={index}>
                            <b>{price?.position} </b>
                        </div>
                    })
                }
            </Col>
            <Col>
                {
                    prices.map((price: any, index: any) => {
                        return <div style={{ margin: "5px 20px" }} key={index}>
                            <Input
                                type="number"
                                style={{ width: 100, margin: "0 8px" }}
                                value={price?.rate}
                                onChange={(e: any) => {
                                    e.persist();
                                    setPrices((prev: any) => {
                                        const newPrices = prices.map((item: any) => {
                                            if (item.position === price.position) {
                                                return {
                                                    ...item,
                                                    rate: e.target.value
                                                }
                                            } else {
                                                return item
                                            }
                                        })
                                        return newPrices;
                                    })
                                }}
                            /> EUR
                            <Input
                                type="number"
                                style={{ width: 100, margin: "0 8px 0 20px" }}
                                value={price?.rate_vat}
                                onChange={(e: any) => {
                                    e.persist();
                                    setPrices((prev: any) => {
                                        const newPrices = prices.map((item: any) => {
                                            if (item.position === price.position) {
                                                return {
                                                    ...item,
                                                    rate_vat: e.target.value
                                                }
                                            } else {
                                                return item
                                            }
                                        })
                                        return newPrices;
                                    })
                                }}
                            /> %
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => adjustPriceDiff(price.position)}
                            >
                                Pielāgot cenu
                            </Button>
                        </div>
                    })
                }
            </Col>
            <Col>
                <b>Kopsumma: {totals.amount.toFixed(2)} EUR</b><br />
                <b>Pēc labojumiem: <span style={{ color: totals.beforeChange !== totals.afterChange ? "red" : "" }}>{(totals.afterChange + totals.difference)} EUR</span></b>
            </Col>
            <Col style={{ display: "flex", flexDirection: "column-reverse", padding: "0 0 0 30px" }}>
                <Button
                    type="primary"
                    disabled={totals.beforeChange !== totals.afterChange}
                    onClick={savePrices}
                >
                    Labot
                </Button>
            </Col>
        </Row>
        : <></>
}