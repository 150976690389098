import { Card, Row, Button, Collapse, Checkbox, message, Input } from "antd";
import pagination from "antd/lib/pagination";
import React, { useEffect, useState } from "react";
import QuestionCard from "../Questions/QuestionCard";
import QuestionsFilter from "../Questions/QuestionsFilter";
import { http } from "../../../helpers/http";
import { SaveOutlined } from '@ant-design/icons';

type QuestionCategory = {
    hard_question_category: 1 | 0;
    hard_question_count: number;
    id: number;
    name_en: string;
    name_lv: string;
    name_ru: string;
}

export const QuestionCategories = (props: any) => {
    const [questionCategories, setQuestionCategories] = useState([] as QuestionCategory[]);

    const getQuestionCategories = () => {
        http.get(`rest/csdd-question-categories`).then((response) => {
            setQuestionCategories(response.data)
        }).catch((error) => {
            console.log(error)
            message.error("Neizdevās iegūt jautājumu kategorijas");
        });
    }

    useEffect(() => {
        getQuestionCategories();
    }, []);

    return <Card
        title={<Row justify="space-between">
            <b>Jautājumu Kategorijas</b>
        </Row>}
        style={{ margin: "8px 24px 24px" }}
    >
        {
            questionCategories?.map((qCategory: QuestionCategory) => {
                return <Card key={qCategory.id}>
                    <Row justify="space-between">
                        <b>{qCategory.name_lv}</b>
                        <div style={{ paddingLeft: 20, borderLeft: "1px solid #F0F0F0" }}>
                            <Row>
                                {
                                    qCategory.hard_question_category === 1
                                        ? <Row style={{ marginRight: 10, paddingRight: 10, borderRight: "1px solid #F0F0F0" }}>
                                            <b>Sarežģītie jautājumi no kategorijas</b>
                                            <Input
                                                value={qCategory.hard_question_count}
                                                type="number"
                                                style={{ marginRight: 10, width: 60, marginLeft: 10 }}
                                                onChange={(e) => {
                                                    setQuestionCategories(questionCategories.map((category) => {
                                                        if (category.id === qCategory.id) {
                                                            return {
                                                                ...category,
                                                                hard_question_count: parseInt(e.target.value)
                                                            }
                                                        }
                                                        return category;
                                                    }));
                                                }}
                                            />
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    http.post(`rest/csdd-question-category-set-hard/${qCategory.id}`, {
                                                        hard_question_category: 1,
                                                        hard_question_count: qCategory.hard_question_count
                                                    }).then(() => {
                                                        message.success("Sarežģīto jautājumu skaits iestatīts");
                                                        getQuestionCategories();
                                                    }).catch((error) => {
                                                        console.log(error)
                                                        message.error("Neizdevās iestatīt sarežģīto jautājumu skaitu");
                                                    });
                                                }}
                                            >
                                                <SaveOutlined />
                                            </Button>
                                        </Row>
                                        : <></>
                                }
                                <Checkbox
                                    checked={qCategory.hard_question_category === 1}
                                    style={{ marginRight: 10 }}
                                    onChange={(e) => {
                                        http.post(`rest/csdd-question-category-set-hard/${qCategory.id}`, {
                                            hard_question_category: e.target.checked ? 1 : 0,
                                            hard_question_count: qCategory.hard_question_count ? qCategory.hard_question_count : 1
                                        }).then(() => {
                                            getQuestionCategories();
                                        }).catch((error) => {
                                            console.log(error)
                                            message.error("Neizdevās iestatīt sarežģīto kategoriju");
                                        });
                                    }}
                                />
                                <span>Sarežģīta kategorija</span>
                            </Row>
                        </div>
                    </Row>
                </Card>
            })
        }
    </Card>
};