import { Card, Row, Col, Input, Select, Button, message, Checkbox, Tooltip } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { AxiosResponse } from "axios";
import React, { useState } from "react";
import { http } from "../../helpers/http";
import { Required, SchoolAdminForm } from "./SchoolAdminForm";
import { Admin, School } from "./SchoolsPage";
import { SchoolPricesForm } from "./SchoolPricesForm";

interface SchoolFormProps {
    school: School;
    admin: [Admin];
    setAdmin: (admin: [Admin]) => void;
    setSchool: (school: School) => void;
    edit: boolean;
    refreshSchools: () => void;
    categories: any[];
    setScreen: (screen: "overview" | "balance" | "invoices") => void;
};

export const SchoolForm = (props: SchoolFormProps) => {
    const {
        school,
        admin,
        setSchool,
        setAdmin,
        edit,
        refreshSchools,
        categories,
        setScreen
    } = props;

    const [loading, setLoading] = useState(false);

    const saveSchool = () => {
        setLoading(true);
        http.post(`rest/schools`, { ...school }).then((res: AxiosResponse) => {
            setLoading(false);
            setSchool({ ...res.data, admin })
            refreshSchools();
        }).catch(() => {
            message.error("Kļūda!");
            setLoading(false);
        })
    };

    return <Card
        title={edit ? "Rediģēt skolu" : "Pievienot jaunu skolu"}
        loading={loading}
    >
        <Row gutter={24}>
            <Col span={8}>
                <p><b>Skolas nosaukums:</b><Required /></p>
                <Input
                    style={{ marginBottom: 10 }}
                    value={school.name}
                    name="name"
                    onChange={(e) => {
                        setSchool({
                            ...school,
                            [e.target.name]: e.target.value.length > 0 ? e.target.value : undefined
                        })
                    }}
                />
                <p><b>Reģistrācijas numurs:</b><Required /></p>
                <Input
                    style={{ marginBottom: 10 }}
                    value={school.reg_nr}
                    name="reg_nr"
                    onChange={(e) => {
                        setSchool({
                            ...school,
                            [e.target.name]: e.target.value.length > 0 ? e.target.value : undefined
                        })
                    }}
                />
                <b style={{ marginBottom: 10, marginRight: 8, display: "inline-block" }}>
                    Atļauts pieteikties Einšteins teorijas nodarbībām:
                </b>
                <Checkbox
                    checked={school.allow_school_study ? true : false}
                    onChange={(e: CheckboxChangeEvent) => {
                        setSchool({
                            ...school,
                            allow_school_study: e.target.checked ? 1 : 0
                        })
                    }}
                />
                <p><b>Statuss:</b></p>
                <Select
                    style={{ width: "100%", marginBottom: 20 }}
                    value={school.active}
                    onChange={(e: School['active']) => {
                        setSchool({
                            ...school,
                            active: e
                        })
                    }}
                >
                    <Select.Option value={1}>Aktīva</Select.Option>
                    <Select.Option value={0}>Neaktīva</Select.Option>
                </Select>
                <p><b>Rekvizīti:</b></p>
                <Input.TextArea
                    rows={5}
                    style={{ marginBottom: 10 }}
                    value={school.school_requisites}
                    name="school_requisites"
                    onChange={(e) => {
                        setSchool({
                            ...school,
                            [e.target.name]: e.target.value.length > 0 ? e.target.value : undefined
                        })
                    }}
                />
                <Row>
                    <Col style={{ padding: 10 }}>
                        <Row>
                            <p style={{ marginRight: 10 }}><b>Rēķina numura sagatave</b></p>
                            <Tooltip
                                style={{ height: 20, width: 20 }}
                                placement="top"
                                title={"Lai izveidotu rēķina numura sagatavi, izmantojiet pogas kuras atrodas zem ievadlauka. Poga pievieno nepieciešamo mainīgo rēķina numura tekstam."}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Row>
                        <Input
                            value={school.invoice_template}
                            name="invoice_template"
                            onChange={(e) => {
                                setSchool({
                                    ...school,
                                    [e.target.name]: e.target.value.length > 0 ? e.target.value : undefined
                                })
                            }}
                        />
                        <Button
                            onClick={() => {
                                const text = school.invoice_template;
                                setSchool({ ...school, invoice_template: text !== undefined && text !== null ? text + "{year}" : "{year}" })
                            }}
                        >
                            Gads
                        </Button>
                        <Button
                            onClick={() => {
                                const text = school.invoice_template;
                                setSchool({ ...school, invoice_template: text !== undefined && text !== null ? text + "{month}" : "{month}" })
                            }}
                        >
                            Mēnesis
                        </Button>
                    </Col>
                </Row>
                <p><b>Apmaksas termiņš dienās:</b><Required /></p>
                <Input
                    style={{ marginBottom: 10 }}
                    value={school.pay_days}
                    name="pay_days"
                    onChange={(e) => {
                        setSchool({
                            ...school,
                            [e.target.name]: e.target.value.length > 0 ? e.target.value : undefined
                        })
                    }}
                />
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                        type="primary"
                        onClick={() => saveSchool()}
                        loading={loading}
                        disabled={school.name === undefined || school.reg_nr === undefined}
                        ghost
                    >
                        {
                            edit
                                ? "Saglabāt"
                                : "Pievienot skolu"
                        }
                    </Button>
                </div>
            </Col>
            {
                edit
                && <>
                    <Col span={8}>
                        <SchoolAdminForm
                            edit={edit}
                            admin={admin}
                            setAdmin={setAdmin}
                            refreshSchools={refreshSchools}
                        />
                    </Col>
                    <Col span={8}>
                        <Card>
                            <Button
                                type="primary"
                                onClick={() => setScreen("balance")}
                            >
                                Uzkrājums
                            </Button>
                            <Button
                                type="primary"
                                style={{ marginBottom: 20, marginLeft: 10 }}
                                onClick={() => setScreen("invoices")}
                            >
                                Rēķini
                            </Button>
                            <Card title="Cenas">
                                <SchoolPricesForm
                                    prices={school.prices ?? []}
                                    setPrices={(prices) => setSchool({ ...school, prices })}
                                    categories={props.categories ?? []}
                                    schoolID={school.id}
                                />
                            </Card>
                        </Card>
                    </Col>
                </>
            }
        </Row>
    </Card>
}