import { Button, Card, Col, Layout, message, PageHeader, Row } from "antd";
import { DeleteOutlined, ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { http } from "../../helpers/http";

const ModuleCard = (props: any) => {
    const history = useHistory();
    const {
        seq,
        removeModule,
        saveModules,
        moveModule,
        lastInd,
        module,
        category
    } = props;

    const ind = seq - 1;
    const editModule = () => {
        history.push(`/e-learning/${category}/edit-module/${module.id}`);
    }

    const removeModuleHandler = (e: any) => {
        if (module.id !== null) http.delete(`/rest/learning-modules/${module.id}`)
        e.stopPropagation()
        removeModule(ind);
    }

    return <Card
        style={{ margin: "15px 0" }}
        hoverable={module.id !== null}
        onClick={module.id !== null ? editModule : () => { }}
        bodyStyle={module.id === null ? { backgroundColor: "rgb(242 241 241)", border: "1px solid #aaaaaa" } : {}}
    >
        {/* <div>
            {index === services.length - 1 ? <></> : <Button value={index} onClick={moveDown} icon={<ArrowDownOutlined style={{ pointerEvents: "none" }} />}></Button>}
            {index !== 0 ? <Button value={index} onClick={moveUp} icon={<ArrowUpOutlined style={{ pointerEvents: "none" }} />}></Button> : <></>}
            <Button onClick={removeService} value={service.id} style={{ marginLeft: 10 }} icon={<DeleteOutlined style={{ pointerEvents: "none" }} />}></Button>
        </div> */}
        <div style={{ position: "absolute", top: 5, right: 5, zIndex: 1 }}>
            {
                ind === 0
                    ? <></>
                    : <Button onClick={(e: any) => { e.stopPropagation(); moveModule(ind, "up") }} type="default" icon={<ArrowUpOutlined style={{ pointerEvents: "none" }} />}></Button>

            }
            {
                lastInd === ind
                    ? <></>
                    : <Button onClick={(e: any) => { e.stopPropagation(); moveModule(ind, "down") }} type="default" icon={<ArrowDownOutlined style={{ pointerEvents: "none" }} />}></Button>

            }
            <Button onClick={(e: any) => removeModuleHandler(e)} type="default" style={{ marginLeft: 10 }} icon={<DeleteOutlined style={{ pointerEvents: "none" }} />}></Button>
        </div>
        <Row>
            <Col span={8}>
                <b>{seq}. Modulis</b>
            </Col>
            <Col span={8}>
                <b>{module.title_lv || "Nav nosaukuma"}</b>
            </Col>
            <Col span={7}>
                <b>{module?.chapters?.length} nodaļas</b>
            </Col>
        </Row>
    </Card>
}

export const CategoryView = withRouter((props: any) => {
    const history = useHistory();
    const { id } = useParams() as any;
    const { catName } = props.history.location.state;

    const [modules, setModules] = useState([] as any);
    const [saved, setSaved] = useState(true);

    const fetchModules = async () => {
        await http.get(`/rest/learning-modules-list/${id}`).then((response: any) => {
            setModules(response.data)
        })
    }

    const addModule = () => {
        setSaved(false);
        setModules((prev: any) => {
            return [
                ...prev,
                {
                    category_id: Number(id),
                    ind: prev.length,
                    id: null,
                    title_lv: null,
                    title_en: null,
                    title_ru: null,
                    description_lv: null,
                    description_en: null,
                    description_ru: null,
                }
            ]
        })
    }

    const removeModule = (ind: any) => {
        setSaved(false);
        setModules((prev: any) => {
            const newList = prev.filter((item: any, index: any) => {
                return index !== ind;
            })
            return newList;
        })
    }

    const moveModule = (ind: any, direction: string) => {
        setSaved(false);
        console.log(direction)
        if (direction === "up" && ind !== 0) {
            setModules((prev: any) => {
                const toMove = prev[ind];
                const inPlaceOf = prev[ind - 1];
                prev[ind] = inPlaceOf;
                prev[ind - 1] = toMove;
                return [...prev];
            })
        } else if (direction === "down" && ind !== modules.length - 1) {
            setModules((prev: any) => {
                const toMove = prev[ind];
                const inPlaceOf = prev[ind + 1];
                prev[ind] = inPlaceOf;
                prev[ind + 1] = toMove;
                return [...prev];
            })
        }
    }

    const saveModules = () => {
        const toSend = modules.map((module: any, index: any) => {
            return {
                ...module,
                ind: index
            }
        });
        http.post(`/rest/learning-modules`, { modules: toSend }).then((response: any) => {
            setSaved(true);
            fetchModules();
            message.success("Dati saglabāti")
        }).catch(() => {
            message.error("Kļūda!");
        });
    }

    useEffect(() => {
        console.log(id, props);
        fetchModules();
    }, [])

    useEffect(() => {
        console.log(modules)
    }, [modules])

    return <Layout className="main-content">
        <PageHeader
            title={`${catName} kategorija`}
            onBack={history.goBack}
            style={{ backgroundColor: "#FFFFFF" }}
        />
        <Card style={{ margin: 15 }}>
            {
                modules.map((module: any, index: any) => {
                    return <ModuleCard
                        key={index}
                        seq={index + 1}
                        lastInd={modules.length - 1}
                        module={module}
                        category={id}
                        saved={saved}
                        removeModule={removeModule}
                        saveModules={saveModules}
                        moveModule={moveModule}
                    />
                })
            }
            <Row justify="space-between" style={{ marginTop: 30 }}>
                <Button onClick={addModule}>
                    Pievienot Moduli +
                </Button>
                <Button type="primary" onClick={saveModules}>
                    Saglabāt
                </Button>
            </Row>
        </Card>
    </Layout>
})