import React from "react";
import { Button, Input, message, Row, Select } from "antd";
import { http } from "../../helpers/http";
import { Price } from "./SchoolsPage";

type SchoolsPricesFormProps = {
    prices: Price[];
    categories: any[];
    setPrices: (prices: Price[]) => void;
    schoolID?: number;
};

export const SchoolPricesForm = ({ ...props }: SchoolsPricesFormProps) => {

    const savePrices = () => {
        http.post(`/rest/save-school-prices/${props.schoolID}`, { prices: props.prices }).then(() => {
            console.log("Prices saved!");
            message.success("Cenas saglabātas!");
        }).catch(() => {
            console.log("Error saving prices!");
            message.error("Kļūda saglabājot cenas!");
        });
    };

    return <div>
        {
            props.categories?.map((category, index) => {
                const price = props.prices.find(price => price.category_id === category.id);
                const priceIndex = props.prices.findIndex((price: Price) => price.category_id === category.id);
                return <Row key={index} align="middle" style={{ marginBottom: 10 }}>
                    {/* <Select
                        style={{ width: "100%" }}
                        value={price?.category_id ?? undefined}
                        onChange={(value) => {
                            const newPrices = [...props.prices];
                            if (price === undefined) {
                                newPrices.push({
                                    category_id: value,
                                    school_price: 0
                                });
                            } else {
                                newPrices[priceIndex] = {
                                    ...price,
                                    category_id: value ?? -1
                                };
                            }
                            props.setPrices(newPrices);
                        }}
                    >
                        {
                            props.categories.map((category, index) => {
                                return <Select.Option key={index} value={category.id}>{category.value}</Select.Option>
                            })
                        }
                    </Select> */}
                    <p style={{ margin: 0 }}><b>{category.value} kategorija</b></p>
                    <Input
                        style={{ width: 100, marginLeft: 20, marginRight: 10 }}
                        type="number"
                        value={price?.school_price ?? 0}
                        onChange={(e) => {
                            const newPrices = [...props.prices];

                            if (priceIndex === -1) {
                                newPrices.push({
                                    category_id: category.id,
                                    school_price: Number(e.target.value)
                                });
                            } else {
                                newPrices[priceIndex] = {
                                    ...price,
                                    school_price: Number(e.target.value) ?? 0
                                };
                            }
                            props.setPrices(newPrices);
                        }}
                    />
                    <b>€</b>
                </Row>
            })
        }
        <Row justify="end">
            <Button
                onClick={() => savePrices()}
                type="ghost"
            >
                Saglabāt
            </Button>
        </Row>
    </div>
};