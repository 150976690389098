import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Layout, message, PageHeader, Row, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Price, School } from "./SchoolsPage";
import { useHistory } from "react-router-dom";
import { http } from "../../helpers/http";
import moment from "moment";
import { AxiosResponse } from "axios";
import { createHtml } from "../../resources/HTML/eDrive_invoice";

type SchoolBalancePageProps = {
    school: School;
    setScreen: (screen: "overview" | "balance" | "invoices") => void;
}

const PayoutForm = (props: any) => {
    const { selectedStudents, period } = props;
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const warning = selectedStudents.some((student: any) => student.price === null || student.price === undefined);

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 8,
        },
    };

    const getInvoiceNumber = () => {
        let invoiceNumber = props.invoiceTemplate
            ?.replaceAll("{year}", moment().format("YYYY"))
            ?.replaceAll("{month}", moment().format("MM"))
        return invoiceNumber
    }

    const createPdf = (html: string) => {

        http.post('rest/get-pdf-result', {
            html
        }, { responseType: 'blob' }).then((response: any) => {
            return new Blob([response.data]);
        }).then((blob: any) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a') as any;
            link.href = url;
            link.setAttribute('download', `test.pdf`);
            document.body.appendChild(link);
            link.click();
            if (link !== null) link.parentNode.removeChild(link);
        });
    }

    const payoutHandler = async (values: any) => {
        const invoicePeriod = period[0].format("DD.MM.YYYY") + " - " + period[1].format("DD.MM.YYYY");
        const euros = calcSum(selectedStudents).toString()?.split(".")[0];
        const cents = calcSum(selectedStudents).toString()?.split(".")[1] ?? 0;
        const sumInWords = await http.get(`edrive-invoices/spoken-number/${euros}/${cents}`).then((res: AxiosResponse) => {
            return res.data;
        }).catch(() => {
            return "";
        });
        // createPdf(createHtml({
        //     INVOICE_NR: values.invoice_number,
        //     DATE: values.date?.format("DD.MM.YYYY"),
        //     PERIOD: invoicePeriod,
        //     REQUISITES: props.school?.school_requisites?.split("\n").join("<br>"),
        //     students: props.selectedStudents,
        //     PAYMENT_SUM: calcSum(selectedStudents).toFixed(2),
        //     PAYMENT_DEADLINE_DAYS: 10,
        //     SUM_IN_WORDS: sumInWords
        // }))
        http.post(`edrive-invoices/create-invoice`, {
            school_id: props.selectedStudents[0].school_id,
            invoice_number: values.invoice_number,
            date: values.date,
            period: period,
            selected_student_ids: props.selectedStudents.map((student: any) => student.id),
            prices: props.selectedStudents.map((student: any) => {
                return {
                    [student.id]: student.price
                }
            }),
            categories: props.selectedStudents.map((student: any) => {
                return {
                    [student.id]: student.category_id
                }
            }),
            language: 1,
            student_price: props.globalPrices,
            html: createHtml({
                INVOICE_NR: values.invoice_number,
                DATE: values.date?.format("DD.MM.YYYY"),
                PERIOD: invoicePeriod,
                REQUISITES: props.school?.school_requisites?.split("\n").join("<br>"),
                students: props.selectedStudents.map((student: any) => {
                    return {
                        ...student,
                        price: Number(student.price ?? 0).toFixed(2)
                    }  
                }),
                PAYMENT_SUM: calcSum(selectedStudents).toFixed(2),
                PAYMENT_DEADLINE_DAYS: 10,
                SUM_IN_WORDS: sumInWords
            })
        }).then((res: AxiosResponse) => {
            props.navigateBack();
            message.success("Rēķins izveidots!");

        }).catch(() => {
            message.error("Kļūda izveidojot rēķinu!");
        });
    }

    const calcSum = (arr: any) => {
        let sum = 0;
        arr.forEach((student: any) => {
            if (student.price === null || student.price === undefined) return;
            sum += Number(student.price);
        });
        return sum;
    }

    useEffect(() => {
        if (props.period !== undefined && props.period !== null) {
            form.setFieldsValue({ period: moment(props.period[0]).format("DD.MM.YYYY") + " - " + moment(props.period[1]).format("DD.MM.YYYY") })
        }
    }, [period]);

    return selectedStudents.length > 0 ? (
        <>
            {
                warning
                    ? <b style={{ color: "red", marginBottom: 10, width: "100%", textAlign: "center" }}>Dažiem studentiem nav norādīta kategorijas pakalpojuma cena!</b>
                    : <></>
            }
            {/* <Button
                style={{ margin: "0 0 10px 0" }}
                type="primary"
                onClick={createPdf}
            >
                Test PDF result
            </Button> */}
            <Form
                form={form}
                {...layout}
                onFinish={payoutHandler}
                initialValues={{
                    invoice_number: getInvoiceNumber()
                }}
            >
                <Form.Item name="invoice_number" label="Rēķina nr.:" required>
                    <Input width={20} name="invoice_number" />
                </Form.Item>
                <Form.Item name="date" label="Rēķina datums:" initialValue={moment().startOf('month').add(9, "day")}>
                    <DatePicker name="date" format="DD.MM.YYYY" />
                </Form.Item>
                <Form.Item
                    name="period"
                    label="Periods:"
                    initialValue={
                        props.period !== undefined && props.period !== null
                            ? moment(props.period[0]).format("DD.MM.YYYY") + " - " + moment(props.period[1]).format("DD.MM.YYYY")
                            : ""
                    }
                >
                    <Input name="period" />
                </Form.Item>
                {/* <Form.Item name="deduction" label="Atvilkuma summa EUR:">
                <InputNumber value={deduction} onChange={deductionHandler} />
            </Form.Item>
            <Form.Item name="deduction_reason" label="Atvilkuma pamatojums:">
                <Input.TextArea name="deduction_reason" autoSize={{ maxRows: 4, minRows: 4 }} style={{ width: 200 }} />
            </Form.Item>
            <Form.Item name="bonus" label="Prēmija">
                <InputNumber />
            </Form.Item>
            <Form.Item style={{}} name="premium" label="Prēmiju piešķirt visām mēneša nodarbībām" valuePropName="checked">
                <Checkbox />
            </Form.Item> */}
                <Form.Item label="Izmaksājamā summa EUR: ">
                    <span style={{ fontWeight: "bold", fontSize: 16 }}>{calcSum(selectedStudents).toString()}</span>
                </Form.Item>
                <Button loading={saving} style={{ margin: "0 0 0 226px" }} type="primary" htmlType="submit">{!saving ? "Izstādīt" : "Saglabā..."}</Button>
            </Form>
        </>
    ) : <></>
}

export const SchoolBalancePage = ({ ...props }: SchoolBalancePageProps) => {
    const [period, setPeriod] = useState([
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month')
    ] as any);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loading, setLoading] = useState(false);
    const [selection, setSelection] = useState<any>([]);
    const [students, setStudents] = useState<any>([]);
    const [prices, setPrices] = useState<Price[]>([]);
    const [individualPrices, setIndividualPrices] = useState<{ [key: number]: number } | undefined>(undefined);

    const getSchoolBalance = (periodSetting: any = period) => {
        setLoading(true);
        http.get(`edrive-invoices/get-school-invoice-data/${props.school.id}`, {
            params: {
                from: moment(periodSetting[0], "DD.MM.YYYY").format("YYYY-MM-DD"),
                to: moment(periodSetting[1], "DD.MM.YYYY").format("YYYY-MM-DD")
            }
        }).then((res: AxiosResponse) => {
            setStudents(res.data.results
                ? res.data.results.map((student: any) => {
                    const globalPrice = res.data?.prices?.find((price: any) => price.category_id === student.category_id)?.school_price;
                    return {
                        ...student,
                        price: globalPrice
                    }
                })
                : []
            );
            setPrices(res.data.prices ?? []);
            setLoading(false);
        }).catch(() => {
            message.error("Kļūda!");
            setLoading(false);
        });
    }

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelection(() => {
                return (
                    selectedRows
                );
            })
        },
        getCheckboxProps: (record: any) => ({
            name: record.id,
        }),
    };

    useEffect(() => {
        getSchoolBalance();
    }, [props.school])

    const columns = [
        {
            title: 'Students',
            render: (student: any, record: any) => {
                return <>
                    {student.first_name} {student.last_name}
                </>
            },
        },
        {
            title: 'Kategorija',
            dataIndex: 'value',
        },
        {
            title: 'Skolas likme',
            dataIndex: 'price',
            render: (price: any, record: any) => {
                let amount = price === null ? prices.find((p: any) => p.category_id === record.category_id)?.school_price : price;
                let edited = individualPrices?.[record.id];
                return <div>
                    <span>{amount} EUR&nbsp;</span>
                    {
                        edited !== null && edited !== undefined
                            ? <small style={{ color: "red" }}>Labots</small>
                            : ""
                    }
                </div>;
            }
        },
    ] as any;

    return <Layout>
        <Row>
            <PageHeader
                style={{ backgroundColor: "#FFFFFF", width: "100%" }}
                title={`Skola: ${props.school?.name || ""}`}
                onBack={() => props.setScreen("overview")}
            />
            <Col span={24} style={{ backgroundColor: "#FFFFFF" }}>
                <Row justify="center" align="middle">
                    <b>Periods: </b>
                    <DatePicker.RangePicker
                        style={{ margin: 20 }}
                        defaultValue={period}
                        format="DD.MM.YYYY"
                        onChange={(e: any) => setPeriod(e)}
                    />
                    <Button
                        style={{ marginLeft: 8 }}
                        onClick={() => {
                            // if (period === null) return;
                            getSchoolBalance(period);
                        }}
                    >
                        Atlasīt
                    </Button>
                </Row>
            </Col>
            <Col span={24}>
                <Card>
                    <Row justify="end">
                        <Select
                            value={pageSize}
                            style={{ width: 150, marginBottom: 10 }}
                            onChange={(e: any) => setPageSize(e)}
                            disabled={loading}
                        >
                            <Select.Option value={10}>10 / lappuse</Select.Option>
                            <Select.Option value={20}>20 / lappuse</Select.Option>
                            <Select.Option value={50}>50 / lappuse</Select.Option>
                            <Select.Option value={100}>100 / lappuse</Select.Option>
                            <Select.Option value={-1}>Visi ieraksti</Select.Option>
                        </Select>
                    </Row>
                    <Table
                        rowKey={record => record.id}
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                        columns={columns}
                        dataSource={students}
                        expandable={{
                            expandedRowRender: (record: any) => {
                                return <Row align="middle">
                                    <b>Individuāla pakalpojuma cena:</b>
                                    <Input
                                        style={{ marginLeft: 10, marginRight: 10, width: 60 }}
                                        type="number"
                                        value={individualPrices?.[record.id]}
                                        onChange={(e) => {
                                            setSelection(selection.map((student: any) => {
                                                if (student.id === record.id) {
                                                    return {
                                                        ...student,
                                                        price: e.target.value
                                                    }
                                                }
                                                return student;
                                            }));
                                            setStudents(students.map((student: any) => {
                                                if (student.id === record.id) {
                                                    return {
                                                        ...student,
                                                        price: e.target.value
                                                    }
                                                }
                                                return student;
                                            }));
                                            if (individualPrices === undefined) {
                                                setIndividualPrices({
                                                    [record.id]: Number(e.target.value)
                                                });
                                                return;
                                            }
                                            setIndividualPrices({
                                                ...individualPrices,
                                                [record.id]: Number(e.target.value)
                                            });
                                        }}
                                    />
                                    <b>€</b>
                                </Row>
                            }
                        }}
                        loading={loading}
                        pagination={
                            pageSize === -1
                                ? false
                                : {
                                    pageSize
                                }}
                        style={{ marginBottom: 20 }}
                    />
                    {
                        pageSize !== -1
                            ? <></>
                            : <Row justify="end">
                                <Select
                                    value={pageSize}
                                    style={{ width: 150 }}
                                    onChange={(e: any) => setPageSize(e)}
                                    disabled={loading}
                                >
                                    <Select.Option value={10}>10 / lappuse</Select.Option>
                                    <Select.Option value={20}>20 / lappuse</Select.Option>
                                    <Select.Option value={50}>50 / lappuse</Select.Option>
                                    <Select.Option value={100}>100 / lappuse</Select.Option>
                                    <Select.Option value={-1}>Visi ieraksti</Select.Option>
                                </Select>
                            </Row>
                    }
                    {
                        selection.length > 0
                        && <PayoutForm
                            selectedStudents={selection}
                            period={period}
                            invoiceTemplate={props.school?.invoice_template}
                            globalPrices={prices}
                            school={props.school}
                            navigateBack={() => props.setScreen("invoices")}
                        />
                    }
                </Card>
            </Col>
        </Row>
    </Layout>
}