import { Card, Col, Table, Descriptions, Layout, PageHeader, Row, Skeleton, Space, Button } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router';
import { http } from '../../helpers/http';
import lodash, { get } from "lodash";
import { PrinterOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { School } from '../SchoolGlobalSettings';
import { AxiosResponse } from 'axios';
import { HowManyLessonsToOneContext } from '../../helpers/HowManyLessonsToOneContext';

export const InvoiceDetails = withRouter((props: any) => {
    const { howManyLessonsToOne } = useContext(HowManyLessonsToOneContext);
    const printable = useRef(null);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [invoice, setInvoice] = useState({} as any);
    const handlePrint = useReactToPrint({
        content: () => printable.current
    })
    const [school, setSchool] = useState<School>({
        name: null,
        registration_nr: null,
        legal_address: null,
        bank: null,
        swift: null,
        bank_account: null
    });

    function calculateAmount(invoice: any) {
        const {
            deduction,
            lessons
        } = invoice;

        let amount = 0;

        lessons.forEach((lesson: any) => {
            const timeSlot = lesson.lesson_time_slot;
            const howManyLessons = howManyLessonsToOne.some((number: Number) => number === timeSlot.how_many_lessons) ? 1 : timeSlot.how_many_lessons;
            timeSlot.lesson_time_slot_prices.forEach((p: any) => {
                if (p.position === "Instruktors") {
                    let trueRate = p?.rate_total;
                    // if (p.is_external) {
                    //     trueRate = lesson.sequence_number < p.extra_start_hours ? p?.rate : p?.extra_rate
                    // } else {
                    //     trueRate = p?.rate;
                    // }
                    amount += howManyLessons * trueRate;
                }
            });
        });

        return amount - deduction;
    }

    function calculateData(invoice: any) {
        const data: any = [];

        const categories = lodash.uniqBy(
            invoice.lessons.map((lesson: any) => lesson.lesson_time_slot.instructor_service.category),
            (category: any) => category.id
        );
        const categoriesById = lodash.keyBy(categories, "id");

        const students = (
            invoice.lessons.map((lesson: any) => {
                const student = { ...lesson.student, category_id: lesson.lesson_time_slot.instructor_service.category.id }
                return student;
            })
        );
        const studentsGroupedByCategoryId = lodash.groupBy(students, "category_id");
        const lessonsGroupedByStudentId = lodash.groupBy(invoice.lessons, (lesson: any) => lesson.student.id);

        let npk = 0;
        let hasVat = false;
        let totalSum = 0;
        let totalSumWithoutVat = 0;

        Object.keys(categoriesById).forEach(categoryId => {
            data.push({
                key: `category-${categoryId}`,
                description: categoriesById[categoryId].value
            });

            const doneStudents: any = [];

            studentsGroupedByCategoryId[categoryId].forEach(student => {

                const studentCategoryLessonsGroupedByPrice = lodash.groupBy(
                    lessonsGroupedByStudentId[student.id].filter((
                        lesson: any) => lesson.lesson_time_slot.instructor_service.category.id == student.category_id
                    ),
                    (lesson: any) => {
                        const timeSlot = lesson.lesson_time_slot;
                        const p = timeSlot.lesson_time_slot_prices.filter(
                            (price: any) => price.position === "Instruktors"
                        )[0];
                        console.log(p)
                        let trueRate = p?.rate_total;
                        // if (p.is_external) {
                        //     trueRate = lesson.sequence_number < p.extra_start_hours ? p?.rate : p?.extra_rate
                        // } else {
                        //     trueRate = p?.rate_calculated.instructor_rate_total;
                        // }

                        return trueRate;
                    }
                );

                console.log(studentCategoryLessonsGroupedByPrice);

                Object.keys(studentCategoryLessonsGroupedByPrice).forEach((priceG: any) => {
                    let sum = 0;
                    let sumWithoutVat = 0;
                    let count = 0;

                    if (doneStudents.indexOf(student.id) > -1) {
                        return;
                    }

                    npk++;

                    studentCategoryLessonsGroupedByPrice[priceG].forEach((lesson: any) => {
                        const timeSlot = lesson.lesson_time_slot;

                        if (timeSlot.instructor_service.category.id != student.category_id) {
                            return;
                        }

                        const howManyLessons = howManyLessonsToOne.some((number: Number) => number === timeSlot.how_many_lessons) ? 1 : timeSlot.how_many_lessons;
                        count += howManyLessons;
                        timeSlot.lesson_time_slot_prices.forEach((p: any) => {
                            if (p.position === "Instruktors") {
                                if (p.rate_vat > 0) {
                                    hasVat = true;
                                }
                                let trueRate = p?.rate_total;
                                // if (p.is_external) {
                                //     trueRate = lesson.sequence_number < p.extra_start_hours ? p?.rate : p?.extra_rate
                                // } else {
                                //     trueRate = p?.rate;
                                // }
                                sum += howManyLessons * Number(trueRate);
                                sumWithoutVat += howManyLessons * Number(trueRate);
                            }
                        });
                    });

                    totalSum += sum;
                    totalSumWithoutVat += sumWithoutVat;
                    if (sumWithoutVat === 0) return;
                    data.push({
                        npk: npk,
                        key: `student-${student.id}-category-${categoryId}-${priceG}`,
                        description: `${student.first_name} ${student.last_name}`,
                        price: Number(priceG).toFixed(2),
                        count: count,
                        sum: sumWithoutVat.toFixed(2)
                    });
                });

                doneStudents.push(student.id);
            });
        });

        data.push({ key: "empty-row" });
        data.push({
            key: "totalBeforeVat",
            price: "Summa bez PVN",
            sum: (Number(totalSumWithoutVat) / (hasVat ? 1.21 : 1)).toFixed(2)
        })
        if (hasVat) {
            data.push({
                key: "vat",
                price: "PVN 21%",
                sum: Number(totalSumWithoutVat - totalSumWithoutVat / (hasVat ? 1.21 : 0)).toFixed(2)
            })
        }

        if (invoice.deduction != 0) {
            totalSum -= invoice.deduction;

            data.push({
                key: "deduction",
                description: `Atvilkums: ${invoice.deduction_reason}`,
                sum: Number(-1 * invoice.deduction).toFixed(2)
            });
        }

        data.push({
            key: "total-sum-with-vat",
            price: "Summa apmaksai",
            sum: Number(totalSum).toFixed(2)
        })

        return data;
    }

    const getRequisites = () => {
        http.get(`rest/global-settings-by-scope/requisites`).then((res: AxiosResponse) => {
            console.log(res.data.data)
            const { data } = res.data;
            setSchool((prev: School): School => {
                const copy = { ...prev };
                Object.keys(copy).forEach(key => {
                    copy[key] = data.find((item: any) => {
                        return item.key === key
                    })?.value ?? null
                })
                return copy
            })
        })
    }

    useEffect(() => {
        getRequisites();
        async function asyncEffect() {
            try {
                const result = await http.get(`/invoices/get-invoice-details/${props.match.params.id}`);
                setInvoice(result.data.data);
                // setLoading(false);
            } catch (e) {
                window.location.href = "/invoices";
            }
        }

        asyncEffect();
    }, []);

    useEffect(() => {
        if (Object.keys(invoice).length > 0) {
            setLoading(false);
        }
    }, [invoice])

    const amount = !loading ? calculateAmount(invoice) : 0;
    const data = !loading ? calculateData(invoice) : [{}];
    const invoice_date = moment(invoice.date).format('DD.MM.YYYY');

    const content = !loading ? (
        <div className='printable' ref={printable}>
            <Row gutter={20}>
                <Col span={12}>
                    <Descriptions bordered size="small">
                        <Descriptions.Item span={3} label={<strong>RĒĶINS Nr.</strong>}>
                            <strong>{invoice.invoice_number}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label="Datums">
                            {invoice_date}
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label="Apmaksas veids">
                            Pārskaitījums
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={12}>
                    <Descriptions bordered size="small">
                        <Descriptions.Item span={3} label={<strong>APMAKSAS SUMMA (EUR)</strong>}>
                            <strong>{amount.toFixed(2)}</strong>
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label={<strong>APMAKSAS TERMIŅŠ</strong>}>
                            <strong>{invoice_date}</strong>
                        </Descriptions.Item>
                        {invoice.period
                            ? <Descriptions.Item span={3} label={`Periods`}>
                                {invoice.period}
                            </Descriptions.Item>
                            : null
                        }
                    </Descriptions>
                </Col>
            </Row>
            <Row style={{ marginTop: 20 }} gutter={20}>
                <Col span={12} style={{ padding: 25, paddingTop: 0, paddingBottom: 0 }}>
                    <strong>PAKALPOJUMA SNIEDZĒJS:</strong><br />
                    {/* {invoice.instructor.first_name} {invoice.instructor.last_name}<br /> */}
                    <div style={{ whiteSpace: "pre-wrap" }}>
                        {invoice.instructor.payment_requisites}
                    </div>
                </Col>
                <Col span={12} style={{ padding: 25, paddingTop: 0, paddingBottom: 0 }}>
                    <strong>PAKALPOJUMA SAŅĒMĒJS:</strong><br />
                    {school.name}<br />
                    Reģ. nr.: {school.registration_nr}<br />
                    Banka: {school.bank}<br />
                    Kods: {school.swift}<br />
                    Konta Nr. {school.bank_account}<br />
                    Juridiskā adrese: {school.legal_address}<br />
                </Col>
            </Row>
            <Row style={{ marginTop: 20 }} gutter={20}>
                <Col span={24}>
                    <Table
                        dataSource={data}
                        pagination={false}
                        columns={[
                            { title: "N.P.K.", dataIndex: "npk" },
                            { title: "Apraksts", dataIndex: "description" },
                            { title: "Daudzums", dataIndex: "count" },
                            { title: "Cena (EUR)", dataIndex: "price" },
                            { title: "Summa", dataIndex: "sum" },
                        ]}
                    />
                </Col>
            </Row>
            <Row className="print-stuff" style={{ marginTop: 20 }} gutter={20}>
                <Col span={24}>
                    <strong style={{ textDecoration: "underline" }}>Maksājuma  pamatojumā lūdzam norādīt rēķina numuru.</strong><br />
                    Rēķins sagatavots elektroniski un derīgs bez paraksta.<br />
                    Sagatavoja: {invoice.instructor.first_name} {invoice.instructor.last_name}
                </Col>
            </Row>
        </div>
    ) : <Skeleton />;

    return <>
        <Layout className="fuckity">
            <PageHeader
                style={{ padding: "0 16px", background: "rgb(255, 255, 255)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}
                onBack={() => { history.goBack(); }}
                title={
                    <>
                        <span style={{ fontSize: "0.7em", fontWeight: "normal" }}>Doties atpakaļ</span>
                        <Space>
                            <span style={{ marginLeft: 40 }}>{`Instruktora rēķins`}</span>
                            {/* <Button onClick={() => { window.print(); }} style={{ marginLeft: 20 }} icon={<PrinterOutlined />}></Button> */}
                            <Button onClick={handlePrint} style={{ marginLeft: 20 }} icon={<PrinterOutlined />}></Button>
                            <Button onClick={() => {
                                http.post(`/invoices/delete-instructor-invoice/${props.match.params.id}`).then(() => {
                                    history.replace("/invoices/instructors");
                                });
                            }} danger icon={<DeleteOutlined />}></Button>
                        </Space>
                    </>
                }
            />
            <Layout.Content style={{ padding: 20, backgroundColor: "#fff", overflowX: "hidden", overflowY: "auto" }}>
                {content}
            </Layout.Content>
        </Layout>;
    </>;
});
