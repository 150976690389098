import { Button, Card, Col, DatePicker, Form, Input, InputNumber, message, Row, Space, Table } from "antd";
import { EditOutlined, DeleteOutlined, CarOutlined, UserOutlined, CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import React, { useContext, useEffect, useState } from "react";
import { http } from "../../helpers/http";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { HowManyLessonsToOneContext } from "../../helpers/HowManyLessonsToOneContext";

export const SimplePaymentEditor = (props: any) => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState({} as any);

    const { initialValues, payment_id } = props;

    // function onFinish(values: any) {
    //     values.date = values.date.format("YYYY-MM-DD");
    //     console.log(values)
    //     setSubmitting(true);
    //     http.post(`/payments/update-student-payment/${payment_id}`, { ...values })
    //         .then(() => {
    //             setSubmitting(false);
    //             message.success("Maksājums saglabāts");
    //             props.onFinish();
    //         }).catch((err: any) => {
    //             message.error(err.response.data.message);
    //             http.post(`/payments/update-student-payment/${payment_id}`, { ...initialFormValues })
    //                 .then(() => {
    //                     setSubmitting(false);
    //                     console.log("values reset")
    //                 })
    //                 .catch((err: any) => {
    //                     setSubmitting(false);
    //                     message.error(err.response.data.message);
    //                 });
    //         });
    // }

    async function onFinish(values: any) {
        console.log(values, props.row)
        setSubmitting(true);
        let allowPost = true;
        if (!!props.row.credit) {
            await http.post(`/payments/check-historical-credit-sum/${props.row.payment_id}`, { new_amount: values.amount }).then((response: any) => {
                if (!(!!response.data.status)) {
                    console.log(response)
                    message.error(response.data.data);
                    allowPost = false;
                    return;
                }
            }).catch((err: any) => {
                message.error(err.response.data.message);
                allowPost = false;
                return;
            })
        }
        if (allowPost) {
            http.post(`/payments/update-student-payment/${props.row.payment_id}`, { ...values })
                .then(() => {
                    setSubmitting(false);
                    message.success("Maksājums saglabāts");
                    props.onFinish();
                })
                .catch((err: any) => {
                    setSubmitting(false);
                    message.error(err.response.data.message);
                });
        }
        setSubmitting(false);
    }

    useEffect(() => {
        console.log(initialValues)
        setInitialFormValues(() => {
            const vals = form.getFieldsValue();
            return { ...vals }
        })
    }, [])

    return <Form initialValues={initialValues} form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={20}>
            <Col span={12}>
                <Form.Item label="Summa EUR" rules={[{ required: true, message: "Obligāts lauks" }]} name="amount">
                    <InputNumber precision={2} />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item label="Datums" rules={[{ required: true, message: "Obligāts lauks" }]} name="date">
                    <DatePicker />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item label="Rekvizīti" name="payment_requisites">
                    <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item label="Pamatojums" rules={[{ required: true, message: "Obligāts lauks" }]} name="reason">
                    <Input />
                </Form.Item>
            </Col>
        </Row>

        <Row gutter={20}>
            <Col>
                <Form.Item>
                    <Button type="primary" loading={submitting} htmlType="submit">Saglabāt maksājumu</Button>
                </Form.Item>
            </Col>
        </Row>
    </Form>
};

export const LessonPaymentEditor = (props: any) => {
    const { howManyLessonsToOne } = useContext(HowManyLessonsToOneContext);
    const { t } = useTranslation();
    const [amount, setAmount] = useState(props.row.amount);
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState({} as any);

    const statusAcceptedToEdit = [
        "done",
        "student_cancelled24",
        "student_didnt_come",
        "exam_passed",
        "exam_failed"
    ]

    const hasInvoice = props.row.student_lesson.instructor_invoice_id === null ? false : true;
    const lessonSubmited = statusAcceptedToEdit.includes(props.row.student_lesson.status);
    // const canEdit = lessonSubmited && !hasInvoice;
    const canEdit = !hasInvoice;


    const initialValues = {
        prices: props.row.student_lesson.lesson_time_slot.lesson_time_slot_prices.map((entry: any) => ({
            id: entry.id,
            position: entry.position,
            rate: entry.rate,
            rate_vat: entry.rate_vat,
            edited: entry.edited
        })),
        default_prices: props.row.student_lesson.lesson_time_slot.lesson_time_slot_prices.map((entry: any) => ({
            position: entry.position,
            rate: entry.rate_calculated.instructor_rate,
            rate_vat: entry.rate_calculated.instructor_rate_vat
        })),
        payment_requisites: props.row.payment_requisites
    };

    async function onFinish(values: any) {
        console.log(values, props.row)
        setSubmitting(true);
        let allowPost = true;
        if (!!props.row.credit) {
            await http.post(`/payments/check-historical-credit-sum/${props.row.payment_id}`, { new_amount: amount }).then((response: any) => {
                if (!(!!response.data.status)) {
                    console.log(response)
                    message.error(response.data.data);
                    allowPost = false;
                    return;
                }
            }).catch((err: any) => {
                message.error(err.response.data.message);
                return;
            })
        }
        if (allowPost) {
            http.post(`/payments/update-student-payment/${props.row.payment_id}`, { ...values })
                .then(() => {
                    setSubmitting(false);
                    message.success("Maksājums saglabāts");
                    props.onFinish();
                })
                .catch((err: any) => {
                    setSubmitting(false);
                    message.error(err.response.data.message);
                });
        }
        setSubmitting(false);
    }

    let how_many_lessons = props.row.student_lesson.lesson_time_slot.how_many_lessons;
    how_many_lessons = howManyLessonsToOne.some((number: Number) => number === how_many_lessons) ? 1 : how_many_lessons;

    function onFieldsChange(a: any) {
        const values = form.getFieldsValue();
        const sum = values.prices.reduce((a: any, x: any) => a + Number(Number(Number(x.rate).toFixed(2)) * (1 + Number(Number(x.rate_vat).toFixed(2)) / 100)), 0);

        if (!isNaN(sum)) {
            setAmount((how_many_lessons * sum * -1).toFixed(2));
        }
    }

    function renderLessonInfo(data: any) {
        const hasLesson = data.student_lesson;
        const instructor_service = hasLesson ? data.student_lesson.lesson_time_slot.instructor_service : {};
        const instructor = hasLesson ? instructor_service.instructor : {};

        return <Space size={0} style={{ fontSize: 13 }} direction="vertical">
            {!hasLesson ? <span>{data.reason}</span> : <>
                {
                    data.cancel_reason
                    && <Space>
                        <span style={{ color: "red", fontWeight: "bold" }}>{t(`cancel_reason.${[data.cancel_reason]}`)}</span>
                    </Space>
                }
                <Space>
                    <span style={{ fontWeight: "bold" }}>({instructor_service.category.value} kat.)</span>

                    <CarOutlined />
                    <span>{instructor_service.vehicle}; {instructor_service.licence_plate}</span>

                    <UserOutlined />
                    <span>{instructor.first_name} {instructor.last_name}</span>
                </Space>
                <Space>
                    <CalendarOutlined />
                    <span>{data.student_lesson.lesson_time_slot.date}</span>
                    <ClockCircleOutlined />
                    <span>{data.student_lesson.lesson_time_slot.time_from} - {data.student_lesson.lesson_time_slot.time_to}</span>
                </Space>
            </>
            }
        </Space>;
    };

    useEffect(() => {
        setInitialFormValues(() => {
            const vals = form.getFieldsValue();
            return { ...vals }
        })
    }, [])

    return <Form onFinish={onFinish} onFieldsChange={onFieldsChange} layout="vertical" initialValues={initialValues} form={form}>
        <Row gutter={20}>
            <Col span={12}>
                <Form.Item label="Summa ar PVN">
                    <span style={{ fontWeight: "bold" }}>{amount}</span>
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item label="Datums">
                    <span style={{ fontWeight: "bold" }}>{moment(props.row.ts, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")}</span>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item label="Rekvizīti" name="payment_requisites">
                    <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
                </Form.Item>
            </Col>
        </Row>

        <Row>
            <Col span={24}>
                {renderLessonInfo(props.row)}
            </Col>
        </Row>

        <Row gutter={20}>
            <Col span={24}>
                <Card title={hasInvoice
                    ? <b>Rēķins ir izrakstīts</b>
                    : lessonSubmited
                        ? <>Rediģēt
                            {initialValues.prices[0].edited !== null
                                ? <small style={{ color: "red", marginLeft: 20 }}>Rediģēts: {initialValues.prices[0].edited}</small>
                                : ""}
                        </>
                        : "Nodarbība vēl nav apstiprināta"
                }
                    style={{ marginTop: 20 }}>
                    <Form.List name="prices">
                        {(fields: any) => (
                            fields.map(({ key, name, fieldKey, ...restField }: any, index: any) => (
                                <Row gutter={20} key={key} style={{ borderBottom: "1px solid #F0F0F0", paddingBottom: 8, marginBottom: 8 }}>
                                    <Col span={12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'position']}
                                            fieldKey={[name, 'position']}
                                            label="Pozīcija"
                                            style={{ marginBottom: 8 }}
                                            rules={[{ required: true, message: "Obligāts lauks" }]}
                                        >
                                            <Input disabled={!canEdit} />
                                        </Form.Item>
                                        <span style={{ marginLeft: 10 }}>{initialValues.default_prices[index].position}</span>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'rate']}
                                            fieldKey={[name, 'rate']}
                                            label="Likme"
                                            style={{ marginBottom: 8 }}
                                            rules={[{ required: true, message: "Obligāts lauks" }]}
                                        >
                                            <InputNumber precision={2} disabled={!canEdit} />
                                        </Form.Item>
                                        <span style={{ marginLeft: 10 }}>{initialValues.default_prices[index].rate}</span>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'rate_vat']}
                                            fieldKey={[name, 'rate_vat']}
                                            label="PVN %"
                                            style={{ marginBottom: 8 }}
                                            rules={[{ required: true, message: "Obligāts lauks" }]}
                                        >
                                            <InputNumber precision={2} disabled={!canEdit} />
                                        </Form.Item>
                                        <span style={{ marginLeft: 10 }}>{initialValues.default_prices[index].rate_vat}</span>
                                    </Col>
                                </Row>
                            ))
                        )}
                    </Form.List>
                </Card>
            </Col>
        </Row >

        <Row gutter={20} style={{ marginTop: 20 }}>
            <Col>
                <Form.Item>
                    <Button type="primary" loading={submitting} htmlType="submit">Saglabāt maksājumu</Button>
                </Form.Item>
            </Col>
        </Row>
    </Form >
};

export const RowEditor = (props: any) => {
    const { row } = props;

    if (row == null) {
        return null;
    }

    if (!row.student_lesson) {
        const initialValues = {
            amount: row.amount,
            date: moment(row.ts, "YYYY-MM-DD HH:mm:ss"),
            reason: row.reason,
            payment_requisites: row.payment_requisites
        };

        return <SimplePaymentEditor onFinish={props.onFinish} row={row} payment_id={row.payment_id} initialValues={initialValues}></SimplePaymentEditor>;
    }

    return <LessonPaymentEditor onFinish={props.onFinish} row={row}></LessonPaymentEditor>;
}

export const StudentPaymentsTable = (props: any) => {
    const { t } = useTranslation();
    const student_id = props.student_id;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(null as any);

    function loadPayments() {
        setData([]);
        if (student_id) {
            setLoading(true);
            http.get(`/payments/for-student/${student_id}`).then(result => {
                setData(result.data.data);
                setLoading(false);
            });
        }
    }

    function editRow(row: any) {
        return () => { setEditing(row); }
    }

    function renderLessonInfo(data: any) {
        const hasLesson = data.student_lesson;
        const instructor_service = hasLesson ? data.student_lesson.lesson_time_slot.instructor_service : {};
        const instructor = hasLesson ? instructor_service.instructor : {};

        return <Space size={0} style={{ fontSize: 13 }} direction="vertical">
            {!hasLesson ? <span>{data.reason}</span> : <>
                {
                    data.cancel_reason
                    && <Space>
                        <span style={{ color: "red", fontWeight: "bold" }}>{t(`cancel_reason.${[data.cancel_reason]}`)}</span>
                    </Space>
                }
                <Space>
                    <span style={{ fontWeight: "bold" }}>({instructor_service.category.value} kat.)</span>

                    <CarOutlined />
                    <span>{instructor_service.vehicle}; {instructor_service.licence_plate}</span>

                    <UserOutlined />
                    <span>{instructor.first_name} {instructor.last_name}</span>
                </Space>
                <Space>
                    <CalendarOutlined />
                    <span>{data.student_lesson.lesson_time_slot.date}</span>
                    <ClockCircleOutlined />
                    <span>{data.student_lesson.lesson_time_slot.time_from} - {data.student_lesson.lesson_time_slot.time_to}</span>
                </Space>
            </>
            }
        </Space>;
    };

    const columns = [
        {
            title: <Space><span>Summa</span></Space>,
            width: 10,
            key: 'sum',
            render: (data: any, all: any) => {
                const prices = data.amount
                console.log(all)
                return <Space direction="vertical">
                    <span style={{ fontWeight: "bold" }}>{(data.amount)}</span>
                </Space>;
            }
        },
        {
            title: 'Maksājuma datums',
            width: 20,
            key: 'student',
            render: (data: any) => {
                const date = `${data.ts}`.split(' ')[0];
                return `${date}`;
            }
        },
        {
            title: 'Pamatojums',
            width: 40,
            key: 'reason',
            render: renderLessonInfo
        },
    ];

    useEffect(() => {
        loadPayments();
    }, [student_id]);

    const local_columns = [
        {
            key: "control",
            render: (data: any) => {
                const canDelete = data.student_lesson_id == null;
                return data.credit_paid
                    ? <></>
                    : <Space>
                        <Button onClick={editRow(data)} icon={<EditOutlined />} title="Labot"></Button>
                        {false /* canDelete */ ? <Button title="Dzēst" danger icon={<DeleteOutlined />}></Button> : null}
                    </Space>;
            },
            width: 20
        },
    ].concat(columns as any);

    return <div>
        <Modal
            footer={null}
            visible={editing != null}
            onCancel={() => { setEditing(null); }}
        >
            <RowEditor row={editing} onFinish={() => { setEditing(null); loadPayments(); }} />
        </Modal>
        <Table
            columns={local_columns as any}
            loading={loading}
            rowKey={record => record.payment_id}
            rowClassName={(data: any) => data.amount > 0
                ? "student-payments-row-plus"
                : data.credit || data.credit_paid
                    ? data.credit ? "student-payments-row-credit" : "student-payments-row-credit-paid"
                    : "student-payments-row-minus"
            }
            pagination={{
                showSizeChanger: false,
                size: 'small',
                showTotal: (total) => <div>Kopā {total} ieraksti</div>,
                position: ['bottomCenter'],
                pageSizeOptions: ['15'],
                defaultPageSize: 15,
            }}
            dataSource={data}
        ></Table>
    </div>;
}