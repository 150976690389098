import { Layout, PageHeader, Row, Col, Card, Form, Button, Input, Select, DatePicker, Modal, message, Checkbox } from 'antd';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { http } from '../helpers/http';

const Content = Layout.Content;
const { confirm } = Modal;

export type NewStudentPageProps = RouteComponentProps & {
};
export type NewStudentPageState = {
    submitting: boolean,
    classifiers: any,
    loaded: boolean,
    haveOrphan: boolean,
    onlyDriving: boolean,
    registrationStatusValues: any
};

export class BaseNewStudentPage extends React.Component<NewStudentPageProps, NewStudentPageState> {

    constructor(props: NewStudentPageProps) {
        super(props);

        this.state = {
            submitting: false,
            classifiers: {},
            loaded: false,
            haveOrphan: false,
            onlyDriving: false,
            registrationStatusValues: {
                need_driving: "Iesācējs",
                refresh_driving: "Iemaņu atsvaidzināšana"
            },
        };
    }

    form = React.createRef<FormInstance>();

    async loadClassifiers() {
        const languages = await http.get("/rest/languages");
        const cities = await http.get("/rest/cities");
        const categories = await http.get("/rest/categories");
        const gender = await http.get("/rest/student-gender-values");

        this.setState({
            classifiers: {
                languages: languages.data.data,
                cities: cities.data.data,
                categories: categories.data.data,
                gender: gender.data,
            }
        });
    };

    fetchIndividualDrivingRegistrationPrice = () => {
        http.get(`rest/get-individual-driving-default-price`).then((response: any) => {
            console.log(response)
            this.form.current?.setFieldsValue({ driving_registration_payment_amount: response.data.data.default_price })
        })
    };

    // getRegistrationStatus = () => {
    //     http.get(`rest/student-registration-status-values`).then((response: any) => {
    //         console.log(response)
    //     })
    // }

    componentDidMount() {
        this.loadClassifiers().then(() => {
            this.setState({ loaded: true })
            this.fetchIndividualDrivingRegistrationPrice();
        });
    }

    onFinish = (values: any) => {
        console.log(values)
        const studObj = this;
        confirm({
            title: 'Pārliecinieties vai personas kods ir ievadīts pareizi!',
            icon: <ExclamationCircleOutlined />,
            content: <div>
                <b>Personas kods: {values.personal_identity_number}</b><br />
                <p>Kļūdas gadījumā studenta profils nebūs sinhronizēts ar CSDD sistēmu</p>
            </div>,
            onOk() {
                return studObj.submitData(values);
            },
            onCancel() { },
        });
    };

    submitData = (values: any) => {
        const valuesToSubmit = this.convertData(values);
        this.setState({ submitting: true });
        http.post("/rest/students", valuesToSubmit).then((response) => {
            console.log("Submitting")
            this.props.history.replace(`/students/edit/${response.data.data.id}`);
        }).catch((error: any) => {
            // if (error.response?.data?.includes("exist")) {
            //     message.error(error.response?.data)
            //     return;
            // }
            message.error("Kļūda!");
            this.setState({ submitting: false });
        });
    }

    convertData = (data: any) => {
        const newData = {
            ...data,
            birthdate: data.birthdate.format("YYYY-MM-DD"),
            need_driving_registration_payment: data.need_driving_registration_payment ? 1 : 0
        }
        if (!!!newData.need_driving_registration_payment) delete newData.need_driving_registration_payment
        return newData;
    }

    onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    render() {
        return this.state.loaded
            ? <Layout>
                <PageHeader
                    style={{ padding: "0 16px", background: "rgb(255, 255, 255)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}
                    onBack={() => { this.props.history.goBack(); }}
                    title={
                        <>
                            <span style={{ fontSize: "0.7em", fontWeight: "normal" }}>Doties atpakaļ</span>
                            <span style={{ marginLeft: 40 }}>Jauns students</span>
                        </>
                    }
                />
                <Content style={{ padding: 20 }}>
                    <Form
                        name="basic"
                        layout="vertical"
                        ref={this.form}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Card style={{ marginBottom: 5 }} title={<strong>Studenta informācija</strong>}>
                            <Row gutter={20}>
                                <Col flex={1}>
                                    <Form.Item name="first_name" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Vārds">
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col flex={1}>
                                    <Form.Item name="last_name" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Uzvārds">
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col flex={1}>
                                    <Form.Item name="gender" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Dzimums">
                                        <Select>
                                            {this.state.classifiers.gender.map((item: any) => {
                                                return <Select.Option
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item === "male"
                                                        ? "Vīrietis"
                                                        : item === "female"
                                                            ? "Sieviete"
                                                            : item === "other"
                                                                ? "Cits"
                                                                : "Nav definēts"}
                                                </Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col flex={1}>
                                    <Form.Item name="phone_number" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Tālrunis">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={20}>
                                <Col flex={1}>
                                    <Form.Item name="email" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }, { type: "email", message: "Nav derīga e-pasta adrese" }]} label="E-pasta adrese">
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col flex={1}>
                                    <Form.Item name="personal_identity_number" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Personas kods">
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col flex={1}>
                                    <Form.Item name="passport_or_id_number" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Pases vai ID numurs">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col flex={1}>
                                    <Form.Item name="declared_address" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Deklarētā adrese">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col flex={1}>
                                    <Form.Item name="requisites" label="Rekvizīti (tikai juridiskai personai!)">
                                        <Input.TextArea autoSize={{ maxRows: 4, minRows: 4 }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col>
                                    <Form.Item name="birthdate" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Dzimšanas datums">
                                        <DatePicker />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item initialValue={false} valuePropName="checked" name="have_orphan" label="Aizgādnis">
                                        <Checkbox onChange={(e: any) => this.setState({ haveOrphan: e.target.checked })} />
                                    </Form.Item>
                                </Col>
                                {
                                    this.state.haveOrphan
                                        ?
                                        <Col span={24} style={{ marginBottom: 30 }}>
                                            <Card title="Aizgādnis">
                                                <Row>
                                                    <Col span={6}>
                                                        <Form.Item name="orphan_name" label="Vārds">
                                                            <Input style={{ width: 150 }} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={6}>
                                                        <Form.Item name="orphan_surname" label="Uzvārds">
                                                            <Input style={{ width: 150 }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item name="orphan_code" label="Personas kods">
                                                            <Input style={{ width: 150 }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item name="orphan_phone" label="Tālruņa nr.">
                                                            <Input style={{ width: 150 }} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                        : <></>
                                }
                                <Col>
                                    <Form.Item name="password" required rules={[{ required: true, whitespace: true, message: "Obligāts lauks" }]} label="Parole">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>

                        <Card style={{ marginBottom: 5 }} title={<strong>Mācību informācija</strong>}>
                            <Row gutter={20}>
                                <Col flex={1}>
                                    <Form.Item name="category_id" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Kategorija">
                                        <Select style={{ width: 150 }}>
                                            {this.state.classifiers.categories
                                                ? this.state.classifiers.categories.map((cat: any) => {
                                                    return <Select.Option key={cat.id} value={String(cat.id)}>{cat.value}</Select.Option>
                                                }) : null}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col flex={1}>
                                    <Form.Item name="transmission" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Pārnesumkārba">
                                        <Select style={{ width: 150 }}>
                                            <Select.Option value="manual">Manuālā</Select.Option>
                                            <Select.Option value="auto">Automātiskā</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col flex={1}>
                                    <Form.Item name="city_id" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Pilsēta">
                                        <Select style={{ width: 150 }}>
                                            {this.state.classifiers.cities
                                                ? this.state.classifiers.cities.map((city: any) => {
                                                    return <Select.Option key={city.id} value={String(city.id)}>{city.value}</Select.Option>
                                                }) : null}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col flex={1}>
                                    <Form.Item name="language_id" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Valoda">
                                        <Select style={{ width: 150 }}>
                                            {this.state.classifiers.languages
                                                ? this.state.classifiers.languages.map((lang: any) => {
                                                    return <Select.Option key={lang.id} value={String(lang.id)}>{lang.value}</Select.Option>
                                                }) : null}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col flex={1}>
                                    <Form.Item
                                        valuePropName='checked'
                                        name="need_driving_registration_payment"
                                        label="Tikai braukšana"
                                    >
                                        <Checkbox
                                            onChange={(e: any) => {
                                                this.setState({
                                                    onlyDriving: e.target.checked
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    this.state.onlyDriving
                                        ? <Col flex={1}>
                                            <Form.Item
                                                style={{ width: 100, marginRight: 30 }}
                                                name="driving_registration_payment_amount"
                                                label={<span>
                                                    Cena
                                                </span>}
                                                required
                                                rules={[{ required: true, message: "Obligāts lauks" }]}
                                            >
                                                <Input type="number" />
                                            </Form.Item>
                                            <Form.Item name="registration_status" required rules={[{ required: true, message: "Obligāts lauks" }]} label="Status">
                                                <Select style={{ width: 200 }}>
                                                    {
                                                        Object.keys(this.state.registrationStatusValues).map((key: any) => {
                                                            return <Select.Option key={key} value={key}>{this.state.registrationStatusValues[key]}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        : <></>
                                }
                            </Row>
                        </Card>
                        <Card style={{ marginBottom: 20 }}>
                            <Row gutter={20}>
                                <Col>
                                    <Button type="primary" loading={this.state.submitting} htmlType="submit">Pievienot</Button>
                                </Col>
                            </Row>
                        </Card>
                    </Form>
                </Content>
            </Layout >
            : <></>
    }

}

export const NewStudentPage = withRouter(BaseNewStudentPage);