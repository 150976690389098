import { Button, Col, Input, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { SaveOutlined, PlusSquareOutlined, DeleteOutlined } from "@ant-design/icons";
import { http } from "../../helpers/http";
import { AxiosResponse } from "axios";

type Discount = {
    min_quantity: number,
    max_quantity: number,
    discount_percentage: number,
    id?: number
}

export const DrivingLessonsDiscountLadder = () => {
    const [discountLadder, setDiscountLadder] = useState<Discount[]>([]);
    // const [newDiscount, setNewDiscount] = useState<any>(undefined as undefined | Discount);

    const getDiscountLadder = () => {
        http.get('rest/driving-discount-ladder').then((response: AxiosResponse) => {
            console.log(response.data);
            setDiscountLadder(response.data);
        }).catch((error) => {
            message.error('Neizdevās iegūt braukšanas nodarbību atlaides');
        });
    };

    const addDiscount = () => {
        setDiscountLadder((prev: Discount[]) => {
            return [...prev, {
                min_quantity: 0,
                max_quantity: 0,
                discount_percentage: 0
            }];
        })
    };

    const removeDiscount = (index: number) => {
        const discount = discountLadder[index];
        const haveId = discount.hasOwnProperty('id');
        if (haveId) {
            Modal.confirm({
                title: 'Vai tiešām vēlaties dzēst šo atlaides iestatījumu?',
                onOk: () => {
                    http.delete(`rest/driving-discount-ladder/${discount.id}`).then((response: AxiosResponse) => {
                        message.success('Atlaide dzēsta');
                        setDiscountLadder((prev: Discount[]) => {
                            return prev.filter((item: Discount, i: number) => i !== index);
                        });
                    }).catch((error) => {
                        message.error('Neizdevās dzēst atlaides iestatījumus');
                    });
                }
            });
            return;
        }
        setDiscountLadder((prev: Discount[]) => {
            return prev.filter((item: Discount, i: number) => i !== index);
        });
    };

    const saveDiscount = (index: number) => {
        const discount = discountLadder[index];
        const haveId = discount.hasOwnProperty('id');
        if (haveId) {
            Modal.confirm({
                title: 'Vai tiešām vēlaties izmainīt šo atlaides iestatījumu?',
                onOk: () => {
                    http.patch(`rest/driving-discount-ladder/${discount.id}`, discount).then((response: AxiosResponse) => {
                        message.success('Atlaide saglabāta');
                    }).catch((error) => {
                        message.error('Neizdevās saglabāt atlaides iestatījumus');
                    });
                }
            });
            return;
        }
        http.post('rest/driving-discount-ladder', discountLadder[index]).then((response: AxiosResponse) => {
            message.success('Atlaide pievienota');
        }).catch((error) => {
            message.error('Neizdevās saglabāt atlaides iestatījumus');
        });
    };

    const handleDataChange = (index: number, key: string, value: any) => {
        setDiscountLadder((prev: Discount[]) => {
            return prev.map((item: Discount, i: number) => {
                if (i === index) {
                    return {
                        ...item,
                        [key]: value
                    }
                }
                return item;
            });
        });
    };

    useEffect(() => {
        getDiscountLadder();
    }, []);

    return <div>
        <Row align="middle" style={{ marginBottom: 20 }}>
            <p><b>Braukšanas nodarbību atlaides</b></p>
            <Button
                type="primary"
                icon={<PlusSquareOutlined />}
                style={{ marginLeft: 20 }}
                onClick={addDiscount}
            />
        </Row>
        {
            discountLadder.length > 0
                ? discountLadder.map((item: any, index: number) => {
                    return <Row key={index} gutter={20} style={{ marginBottom: 10 }}>
                        <Col>
                            <b>Slotu daudzums no:</b>
                            <Input
                                value={item.min_quantity}
                                type="number"
                                style={{ width: 70, marginLeft: 10 }}
                                step={1}
                                onChange={(e) => handleDataChange(index, 'min_quantity', e.target.value)}
                            />
                        </Col>
                        <Col>
                            <b>Slotu daudzums līdz:</b>
                            <Input
                                value={item.max_quantity}
                                type="number"
                                style={{ width: 70, marginLeft: 10 }}
                                step={1}
                                onChange={(e) => handleDataChange(index, 'max_quantity', e.target.value)}
                            />
                        </Col>
                        <Col>
                            <b>Atlaide procentos:</b>
                            <Input
                                value={item.discount_percentage}
                                type="number"
                                style={{ width: 70, marginLeft: 10 }}
                                onChange={(e) => handleDataChange(index, 'discount_percentage', e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                icon={<SaveOutlined />}
                                style={{ marginRight: 10 }}
                                onClick={() => saveDiscount(index)}
                            />
                            <Button
                                danger
                                type="primary"
                                icon={<DeleteOutlined />}
                                style={{ marginRight: 10 }}
                                onClick={() => removeDiscount(index)}
                            />
                        </Col>
                    </Row>
                })
                : <p>Nav iestatīta neviena atlaide</p>
        }
    </div>
};