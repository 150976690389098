import { Button, Card, Checkbox, Input, message } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { http } from "../helpers/http";
import { useHistory } from "react-router-dom";
import { DrivingLessonsDiscountLadder } from "./Options/DrivingLessonsDiscountLadder";

export const DrivingGlobalSettings = (props: any) => {
    const history = useHistory();
    const [drivingExamManualRequest, setDrivingExamManualRequest] = useState(null as any);
    const [drivingRegistrationRequest, setDrivingRegistrationRequest] = useState(null as any);
    const [loading, setLoading] = useState(false);

    const getDrivingSettings = async () => {
        setLoading(true);
        await http.get(`rest/global-settings-by-key/driving_exam_manual_request`).then((response: any) => {
            const { data } = response.data;
            if (data === null) return;
            setDrivingExamManualRequest(!!Number(data.value));
            // setLoading(false);
            console.log(response)
        })
        await http.get(`rest/global-settings-by-key/individual_driving_registration_price`).then((response: any) => {
            const { data } = response.data;
            if (data === null) return;
            setDrivingRegistrationRequest(Number(data.value));
        })
        setLoading(false);
    };

    const setDrivingSettings = (value: number) => {
        http.post(`rest/global-settings`, {
            key: "driving_exam_manual_request",
            scope: "driving",
            value: value
        }).then((response: any) => {
            console.log(response)
            message.success("Saglabāts!");
        });
    };

    const saveDrivingRegistartionPrice = () => {
        http.post(`rest/global-settings`, {
            key: "individual_driving_registration_price",
            scope: "driving",
            value: drivingRegistrationRequest
        }).then((response: any) => {
            console.log(response)
            message.success("Saglabāts!");
        });
    };

    useEffect(() => {
        getDrivingSettings();
    }, []);

    return <>
        <Card loading={loading}>
            <Checkbox
                style={{ marginRight: 10 }}
                checked={drivingExamManualRequest}
                onChange={(e: any) => {
                    setDrivingSettings(e.target.checked ? 1 : 0)
                    setDrivingExamManualRequest(e.target.checked)
                }}
            />
            <b>Jānovirza uz eksāmenu atkārtoti</b>
        </Card>
        <Card loading={loading}>
            <b> Braukšanas pakalpojuma reģistrācijas maksa</b>
            <Input
                style={{ marginLeft: 10, width: 70 }}
                type="number"
                min={0}
                step={0.01}
                value={drivingRegistrationRequest}
                onChange={(e: any) => {
                    e.persist();
                    setDrivingRegistrationRequest((prev: any) => {
                        return e.target.value
                    })
                }}
            />
            <Button
                style={{ marginLeft: 8 }}
                type="primary"
                icon={<SaveOutlined />}
                onClick={saveDrivingRegistartionPrice}
            />
        </Card>
        <Card>
            <DrivingLessonsDiscountLadder />
        </Card>
        <Card>
            <Button
                type="primary"
                onClick={() => history.push("/options/settings/search-exceptions")}
            >
                Instruktoru meklēšanas izņēmumi
            </Button>
        </Card>
        {/* <Card loading={loading}>
            <b>Atcelšanas maksa 24st. pirms nodarbības (%)</b>
            <Input
                style={{ marginLeft: 10, width: 70 }}
                type="number"
                min={0}
                step={0.01}
                value={drivingRegistrationRequest}
                onChange={(e: any) => {
                    e.persist();
                    setDrivingRegistrationRequest((prev: any) => {
                        return e.target.value
                    })
                }}
            />
            <Button
                style={{ marginLeft: 8 }}
                type="primary"
                icon={<SaveOutlined />}
                onClick={saveDrivingRegistartionPrice}
            />
        </Card> */}
    </>
}